import Queries from "GraphQL/InstructorAccess.js";
import React from "react";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

export default {
  useSubscribedUserStatusQuery: () => {
    const { loading, data, subscribeToMore } = useQuery(Queries.USERS.STATUS, {
      returnPartialData: true
    });

    React.useEffect(() => {
      if (subscribeToMore) {
        return subscribeToMore({
          document: Queries.USERS.STATUS_SUBSCRIPTION,
          updateQuery: (cachedDataOrig, { subscriptionData }) => {
            // After switching to WebSocket, the cachedData already has our changes, so...
            const cachedData = JSON.parse(JSON.stringify(cachedDataOrig));
            return cachedData;
          }
        });
      }
    }, [subscribeToMore]);

    return { loading, data };
  },
  useSubscribedClassQuery: () => {
    const { loading, data, subscribeToMore } = useQuery(Queries.CLASSES.ALL);

    React.useEffect(() => {
      if (subscribeToMore) {
        return subscribeToMore({
          document: Queries.CLASSES.ALL_SUBSCRIPTION,
          updateQuery: (cachedDataOrig, { subscriptionData }) => {
            if (!subscriptionData.data) return cachedDataOrig;

            const cachedData = JSON.parse(JSON.stringify(cachedDataOrig));
            subscriptionData = JSON.parse(JSON.stringify(subscriptionData));

            if (subscriptionData.data.onClassChange) {
              // The id->pk mapping gets lost with the subscriptions
              subscriptionData.data.onClassChange.id =
                subscriptionData.data.onClassChange.id ||
                subscriptionData.data.onClassChange.pk;

              // If one already exists, replace it.
              let filteredItems = [];
              if (cachedData.listClasses) {
                if (subscriptionData.data.onClassChange.deleted) {
                  filteredItems = cachedData.listClasses.items.filter(val => {
                    return val.id !== subscriptionData.data.onClassChange.id;
                  });
                } else {
                  filteredItems = [...cachedData.listClasses.items];
                  let found = filteredItems.find(
                    val => val.id === subscriptionData.data.onClassChange.id
                  );
                  if (found) {
                    Object.assign(found, subscriptionData.data.onClassChange);
                  } else {
                    filteredItems.push(subscriptionData.data.onClassChange);
                  }
                }
              }

              // If the data argument isn't a copy, this will fail to trigger an update
              cachedData.listClasses.items = filteredItems;
            }

            return cachedData;
          }
        });
      }
    }, [subscribeToMore]);

    return { loading, data };
  },
  useStudentQuery: ()=>{ // without subscription
    const { loading, data, error, fetchMore } = useQuery(Queries.STUDENTS.ALL,  {
      variables: {        
        limit: 500
      }});
      React.useEffect(()=>{
        if (data?.listStudents?.nextToken && fetchMore)
        {
          fetchMore({ variables: { nextToken: data.listStudents.nextToken },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
  
                        let orig = JSON.parse(JSON.stringify(prev));
                        const merged = {};
                        orig.listStudents.items.forEach(item=>{
                          merged[item.id] = item;
                        });
                        fetchMoreResult.listStudents.items.forEach(item=>{
                          // Override any duplicates
                          merged[item.id] = item;
                        });
                        
                        orig.listStudents.items = Object.values(merged);
                        orig.listStudents.nextToken = fetchMoreResult.listStudents.nextToken;
  
                        return orig;
                      }
                    });
        }
      },[data, fetchMore, data?.listStudents?.nextToken ]);

      return { loading: loading || Boolean(data?.listStudents?.nextToken), data };

  },
  useSubscribedLessonModQuery: () => {
    const { loading, data, subscribeToMore, fetchMore } = useQuery(
        Queries.LESSON_MOD.ALL
    );
    React.useEffect(() => {
      if (data?.listLessonMod?.nextToken && fetchMore)
      {
        fetchMore({varables: { nextToken: data.listLessonMod.nextToken},
          updateQuery: (prev, {refetchResult}) => {
            if (!refetchResult) return prev;

            let orig = JSON.parse(JSON.stringify(prev));
            const merged = {};
            orig.listLessonMod.items.forEach(item=> {
              merged[item.id] = item;
            });
            refetchResult.listLessonMod.items.forEach(item=>{
              // Override any duplicates
              merged[item.id] = item;
            });

            orig.listLessonMod.items = Object.values(merged);
            orig.listLessonMod.nextToken = refetchResult.listLessonMod.nextToken;

            return orig;
          }
        })
      }
    },[data, fetchMore, data?.listLessonMod?.nextToken]);

    React.useEffect(() => {
      if (subscribeToMore) {
        return subscribeToMore({
          document: Queries.LESSON_MOD.ALL_SUBSCRIPTION,
          updateQuery: (cachedData, {subscriptionData}) =>{
            if (!subscriptionData.data) return cachedData;

            let rval = JSON.parse(JSON.stringify(cachedData));

            const onLessonModChange = {
              ...subscriptionData.data.onLessonModChange,
              id:
              subscriptionData.data.onLessonModChange.id ||
              subscriptionData.data.onLessonModChange.pk
            };
            
            rval.listLessonMod.items = rval.listLessonMod.items.filter(val => {
              return val.id != onLessonModChange.id;
            })
            
            if (!onLessonModChange.deleted) {
              rval.listLessonMod.items.push(onLessonModChange);
            }
            
            return rval;
          }
        })
      }
    }, [subscribeToMore]);

    return { loading: loading || Boolean(data?.listStudents?.nextToken), data };
  },
  useSubscribedStudentsQuery: () => {
    const { loading, data, error, subscribeToMore, fetchMore } = useQuery(Queries.STUDENTS.ALL,  {
      variables: {        
        limit: 500
      }});
    if (error){ console.log("STUDENTS.ALL errors:",error); }

    React.useEffect(()=>{
      if (data?.listStudents?.nextToken && fetchMore)
      {
        fetchMore({ variables: { nextToken: data.listStudents.nextToken },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;

                      let orig = JSON.parse(JSON.stringify(prev));
                      const merged = {};
                      orig.listStudents.items.forEach(item=>{
                        merged[item.id] = item;
                      });
                      fetchMoreResult.listStudents.items.forEach(item=>{
                        // Override any duplicates
                        merged[item.id] = item;
                      });
                      
                      orig.listStudents.items = Object.values(merged);
                      orig.listStudents.nextToken = fetchMoreResult.listStudents.nextToken;

                      return orig;
                    }
                  });
      }
    },[data, fetchMore, data?.listStudents?.nextToken ]);

    React.useEffect(() => {
      if (subscribeToMore) {
        return subscribeToMore({
          document: Queries.STUDENTS.ALL_SUBSCRIPTION,
          updateQuery: (cachedData, { subscriptionData }) => {
            if (!subscriptionData.data) return cachedData;

            let rval = JSON.parse(JSON.stringify(cachedData));
            //rval.listStudents = [...rval.listStudents]

            // The subscribeToMore doesn't do the pk->id conversion correctly.
            const onStudentsChange = {
              ...subscriptionData.data.onStudentsChange,
              id:
                subscriptionData.data.onStudentsChange.id ||
                subscriptionData.data.onStudentsChange.pk
            };
            // If one already exists, replace it.
            rval.listStudents.items = rval.listStudents.items.filter(val => {
              return val.id != onStudentsChange.id;
            });

            if (!onStudentsChange.deleted) {
              rval.listStudents.items.push(onStudentsChange);
            }

            return rval;
          }
        });
      }
    }, [subscribeToMore]);

    return { loading: loading || Boolean(data?.listStudents?.nextToken), data };
  },
  useSubscribedMarketsQuery: () => {
    const { loading, data, subscribeToMore } = useQuery(Queries.MARKETS.ALL);

    React.useEffect(() => {
      if (subscribeToMore) {
        return subscribeToMore({
          document: Queries.MARKETS.ALL_SUBSCRIPTION,
          updateQuery: (cachedDataOrig, { subscriptionData }) => {
            if (!subscriptionData.data) return cachedDataOrig;

            const cachedData = JSON.parse(JSON.stringify(cachedDataOrig));

            // Subscription looses pk=>id mapping
            subscriptionData.data.onMarketChange.id =
              subscriptionData.data.onMarketChange.id ||
              subscriptionData.data.onMarketChange.pk;

            // If one already exists, replace it.
            const filteredItems = cachedData.listMarkets.items.filter(val => {
              return val.id != subscriptionData.data.onMarketChange.id;
            });

            if (!subscriptionData.data.onMarketChange.deleted) {
              filteredItems.push(subscriptionData.data.onMarketChange);
            }

            cachedData.listMarkets.items = filteredItems;

            return cachedData;
          }
        });
      }
    }, [subscribeToMore]);

    return { loading, data };
  },
  useSubscribedTrainersQuery: () => {
    const { loading, data, subscribeToMore } = useQuery(Queries.TRAINERS.ALL);

    React.useEffect(() => {
      if (subscribeToMore) {
        return subscribeToMore({
          document: Queries.TRAINERS.ALL_SUBSCRIPTION,
          updateQuery: (cachedDataOrig, { subscriptionData }) => {
            if (!subscriptionData.data) return cachedDataOrig;

            const cachedData = JSON.parse(JSON.stringify(cachedDataOrig));
            subscriptionData = JSON.parse(JSON.stringify(subscriptionData));

            subscriptionData.data.onTrainerChange.id =
              subscriptionData.data.onTrainerChange.id ||
              subscriptionData.data.onTrainerChange.pk;

            const filteredItems = cachedData.listTrainers.items.filter(val => {
              return val.id != subscriptionData.data.onTrainerChange.id;
            });

            if (!subscriptionData.data.onTrainerChange.deleted) {
              filteredItems.push(subscriptionData.data.onTrainerChange);
            }

            cachedData.listTrainers.items = filteredItems;

            return cachedData;
          }
        });
      }
    }, [subscribeToMore]);

    return { loading, data };
  }
};
