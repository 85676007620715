import React from "react";
import ReactTable from "react-table-6";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import BuildIcon from "@material-ui/icons/Build";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Chip from "@material-ui/core/Chip";
import distiAuth from "disti-auth";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LessonModEdit from "views/Pages/LessonModEdit.js";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Queries from "GraphQL/InstructorAccess.js";
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js";
import { useQuery, useMutation, useSubscription } from "@apollo/client";


const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const DefaultColumnFilter = ({ filter, onChange }) => {
    return (
        <input
        value={filter ? filter.value : ""}
        onChange={e => {
            //console.log("Got onChange: "+ JSON.stringify(e.target.value));
            onChange(e.target.value || []); // Set undefined to remove the filter entirely
        }}
        placeholder={`🔍`} //Search ${count} records...`}
        />
    );
};

const useStyles = makeStyles(styles);

export default function ManageLessonMods() {
    const { allowedActions } = React.useContext(distiAuth.AllowedActionsContext);
    const { loading: contentLoading, data: contentData } = useQuery(
      Queries.CONTENT.ALL
    );
    let { loading: modLoading, data: modData } = SubscriptionHelpers.useSubscribedLessonModQuery();
    // useQuery(
    //     Queries.LESSON_MOD.ALL
    // );

    const { loading: marketLoading, data: marketData, refetchMarkets } = useQuery(
        Queries.MARKETS.ALL
    );
    
    const [updateLessonMod] = useMutation(Queries.LESSON_MOD.MODIFY);

    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
    const [menuTargetData, setMenuTargetData] = React.useState(null);
    
    const [contentEditData, setContentEditData] = React.useState(null);

    let reactTable = React.useRef(null);
    const [pageSize, setPageSize] = React.useState(null);
    
    let tableData = [];

    let marketDataById = {};
    if (marketData && marketData.listMarkets.items) {
        marketData.listMarkets.items.forEach(item => {
        marketDataById[item.id] = item;
        });
    }

    
    if(modData && modData.listLessonMod && modData.listLessonMod.items &&
       contentData && contentData.listContent && contentData.listContent.items)
    {
        modData.listLessonMod.items.forEach(item => {
            let contentName = "temp";
            for(let content in contentData.listContent.items){
                if(contentData.listContent.items[content].id === item.content) {
                    contentName = contentData.listContent.items[content].name;
                }
            }
            
            const baseRow = {
                dateCreated: item.dateCreated, //moment.unix(item.dateCreated).format("DD MMM YYYY"),
                marketCreated: item.marketCreated,
                content: item.content,
                contentName: contentName,
                availableMarkets: item.availableMarkets,
                valid: item.valid,
                pk: item.id,
                name: item.name,
                zipGuid: item.zipGuid
            };
            baseRow.actions = (
                <>
                    <Button
                        color="primary"
                        round
                        size="sm"
                        onClick={event => {
                            setMenuAnchorElement(event.currentTarget);
                            setMenuTargetData(baseRow);
                        }}
                    >
                        <BuildIcon />
                        <ArrowDropDownIcon />
                    </Button>
                </>
            );
            tableData.push(baseRow);
        });
    }

    const onApplyLessonMod = React.useCallback(newData => {
        console.log(
            "Got apply!" +
              JSON.stringify({
                newData
              })
          );

          updateLessonMod({
            variables: {
              pk: newData.pk,
              dateCreated: newData.dateCreated,
              marketCreated: newData.marketCreated,
              content: newData.content,
              valid: newData.valid,
              availableMarkets: newData.availableMarkets || [],
              name: newData.name,
              zipGuid: newData.zipGuid
            }
          });
    }, [modData]);
    
    const handleCloseMenu = () => {
        setMenuAnchorElement(null);
        setMenuTargetData(null);
    };

    const classes = useStyles();
    return (
        <>
            <LessonModEdit
                open={Boolean(contentEditData)}
                onApply={newData => onApplyLessonMod(newData)}
                onClose={() => setContentEditData(null)}
                initialValue={contentEditData}
            />
            <Menu
                id="action-menu"
                anchorEl={menuAnchorElement}
                open={Boolean(menuAnchorElement)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        setContentEditData(menuTargetData);
                        handleCloseMenu();
                    }}
                >
                    Edit
                </MenuItem>
            </Menu>
            <GridContainer>
                <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                        <PlaylistAddCheckIcon />
                    </CardIcon>
                    <h1 className={classes.cardIconTitle}>Manage Lesson Modifications</h1>
                    </CardHeader>
                    <CardBody>
                    <ReactTable
                        ref={r => {
                        reactTable.current = r;
                        }}
                        data={tableData}
                        filterable
                        style={
                        {
                            //height: "calc( 75vh - 276px)"
                        }
                        }
                        defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        if (row[id] !== undefined) {
                            const haystack = String(row[id]).toLowerCase();
                            const needle = String(filter.value).toLowerCase();
                            return haystack.includes(needle);
                        }
                        return true;
                        }}
                        columns={[
                        {
                            Header: "Date Created",
                            accessor: "dateCreated",
                            Filter: DefaultColumnFilter,
                            Cell: row => (
                                <span>
                                {row.value ? moment.unix(row.value).format("DD MMM YYYY") : "?"}
                                </span>
                            )
                        },
                        {
                            Header: "Market Created",
                            accessor: "marketCreated",
                            Filter: DefaultColumnFilter,
                            Cell: row =>
                            row.value
                                ? <Chip
                                    style={{
                                    margin: "1px"
                                    }}
                                    label={
                                        marketLoading ? "..."
                                        : marketDataById[row.value]
                                        ? marketDataById[row.value].name
                                        : "Deleted Market"
                                    }
                                    key={row.value}
                                />
                                : ""
                        },
                        {
                            Header: "Content",
                            accessor: "contentName",
                            Filter: DefaultColumnFilter,
                        },
                        {
                            Header: "Name",
                            accessor: "name",
                            Filter: DefaultColumnFilter,
                        },
                        {
                            Header: "Available in Market",
                            accessor: "availableMarkets",
                            sortable: false,
                            filterable: false,
                            Cell: row =>
                            row.value
                                ? row.value.map(item => {
                                    return (
                                    <Chip
                                        style={{
                                        margin: "1px"
                                        }}
                                        label={
                                            marketLoading ? "..."
                                            : marketDataById[item]
                                            ? marketDataById[item].name
                                            : "Deleted Market"
                                        }
                                        key={item}
                                    />
                                    );
                                })
                                : ""
                        },
                        {
                            Header: "Valid",
                            accessor: "valid",
                            sortable: false,
                            filterable: false,
                            maxWidth: 80,
                            Cell: params =>
                              params.value ? ( <>Yes</> ) : ( <i>- - -</i> )
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        }
                        ]}
                        defaultSorted={[
                        {
                            id: "dateCreated",
                            desc: true
                        }
                        ]}
                        onFilteredChange={() => {
                        setPageSize(
                            reactTable.current.getResolvedState().sortedData.length
                        );
                        }}
                        pageSize={pageSize != null ? pageSize : tableData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                    />
                    </CardBody>
                </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}