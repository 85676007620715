import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import XLSX from "xlsx";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import CardActionArea from "@material-ui/core/CardActionArea";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import LinearProgress from "@material-ui/core/LinearProgress";
import settings from '../../aws-exports.json';

import util from "util.js";

import StudentHistory from "components/StudentHistory/StudentHistory.js";
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Queries from "GraphQL/InstructorAccess.js";

import distiAuth from "disti-auth.js";

distiAuth.init({ userType: "INSTRUCTOR" });

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function bulkImportDialog({
  availableOrganizations,
  defaultOrganization,
  onCreate,
  hiddenFileInput,
  getCurrentEntryByEmail
}) {
  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL);

  const { allowedActions, assignedMarkets } = React.useContext(
    distiAuth.AllowedActionsContext
  );

  let allAllowedMarkets = [];
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items) {
    const assignedMarketsSet = new Set(assignedMarkets);
    allAllowedMarkets = marketQueryData.listMarkets.items.filter(item => {
      return (
        allowedActions.noMarketRestrictions || assignedMarketsSet.has(item.id)
      );
    });

    // Getting all the market names for a few error conditions
    marketQueryData.listMarkets.items.forEach(item => {
      marketDataById[item.id] = item;
    });
  }
  const allAllowedMarketsSet = new Set(allAllowedMarkets.map(item => item.id));

  const [showBulkAdd, setShowBulkAdd] = React.useState(false);

  //const [ bulkAddData, setBulkAddData ] = React.useState(null);

  const [skipHeader, setSkipHeader] = React.useState(false);

  const [rawImportData, setRawImportData] = React.useState({});

  const [selectedMarket, setSelectedMarket] = React.useState("");

  let [warningsAcknowledged, setWarningsAcknowledged] = React.useState(
    new Set()
  );

  const [doingBulkAdd, setDoingBulkAdd] = React.useState(null);

  const addRemoveWarningsAcknowledged = (add, email) => {
    let copy = new Set(warningsAcknowledged);
    if (add) {
      copy.add(email);
    } else {
      copy.delete(email);
    }
    setWarningsAcknowledged(copy);
  };
  let [
    marketWarningsAcknowledged,
    setMarketWarningsAcknowledged
  ] = React.useState(new Set());
  const addRemoveMarketWarningsAcknowledged = (add, email) => {
    let copy = new Set(marketWarningsAcknowledged);
    if (add) {
      copy.add(email);
    } else {
      copy.delete(email);
    }
    setMarketWarningsAcknowledged(copy);
  };
  const okayToAddAll = () => {
    return (
      bulkAddData &&
      !bulkAddData.status.errors &&
      selectedMarket &&
      bulkAddData.status.warningsCount <=
      warningsAcknowledged.size + marketWarningsAcknowledged.size
    );
  };

  const handleBulkImport = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you to import " + bulkAddData.items.length + " users?"
    );

    if (isConfirmed) {
      for (let entryIndex in bulkAddData.items) {
        let entry = bulkAddData.items[entryIndex];
        //console.log("Adding item: " + entry.email);
        setDoingBulkAdd({
          message: "Adding student: " + entry.email,
          percent: (entryIndex / bulkAddData.items.length) * 100.0
        });
        if (!showBulkAdd) {
          break;
        }
        await onCreate({
          createNew: true,
          email: entry.email,
          fullName: entry.fullName,
          market: selectedMarket,
          dealer: entry.dealer,
          personId: entry.personId,
        });
      }

      setDoingBulkAdd(null);
      setShowBulkAdd(false);
    }
  };

  /** Market logic
    * Situations
    *  - Existing student has no market => No problem
    *  - Existing student has a market 
            and it is the same as the new market => No problem
    *  - Existing student has a market 
            and it is different than the new market, 
            but the Trainer has permissions to both => Warning
    *  - Existing student has a market 
            and it is different than the new market, 
            but the Trainer does not have permissions to the existing market => Error
    */

  let bulkAddData = {};

  const calcBulkAddData = () => {
    let calculated = [];
    let status = { errors: false, warningsCount: 0 };

    if (rawImportData && rawImportData.items) {
      rawImportData.items.forEach((entry, entryIndex) => {
        if (!skipHeader || entryIndex != 0) {
          const email = entry.email.toLowerCase();
          const fullName = entry.fullName;
          let personId = entry.personId;

          const emailGood = util.checkEmailFormatGood(email);
          const existingEntryById = getCurrentEntryByEmail(email);
          const emailUnique = !existingEntryById;
          const alternateFullName = existingEntryById
            ? existingEntryById.fullName
            : null;
          // Name conflict is when the email matches but the full name doesn't
          const fullNameConflict =
            existingEntryById && alternateFullName != fullName;
          const internalDuplicate =
            rawImportData.items.filter(d => d.email.toLowerCase() == email.toLowerCase()).length > 1;

          status.errors |= internalDuplicate || !emailGood;
          status.warningsCount += fullNameConflict ? 1 : 0;

          const currentMarket = existingEntryById
            ? existingEntryById.market
            : null;

          const marketError =
            currentMarket && selectedMarket
              ? currentMarket != selectedMarket &&
                !allAllowedMarketsSet.has(currentMarket)
                ? "Cannot move student from market '" +
                (marketDataById[currentMarket]
                  ? marketDataById[currentMarket].name
                  : "") +
                "'"
                : ""
              : "";

          status.errors |= marketError;

          // Don't confuse the issue by adding a warning if we already have an error
          const marketWarning =
            !marketError &&
              (selectedMarket && currentMarket && currentMarket != selectedMarket)
              ? "This will change the student's Market"
              : "";

          status.warningsCount += marketWarning ? 1 : 0;

          let resultingMarket = currentMarket;
          if (selectedMarket && !marketError) {
            if (marketWarning) {
              if (marketWarningsAcknowledged.has(email)) {
                resultingMarket = selectedMarket;
              }
            } else {
              resultingMarket = selectedMarket;
            }
          }

          let alternatePersonId = (existingEntryById && existingEntryById.personId)
            ? existingEntryById.personId
            : null;

          const personIdEmpty = alternatePersonId && !personId;
          personId = personIdEmpty ? alternatePersonId : personId;

          const personIdConflict =
            personId && personId !== alternatePersonId;
          status.warningsCount += personIdConflict ? 1 : 0;

          let newEntry = {
            row: entry.row,
            email,
            fullName,
            emailGood,
            emailUnique,
            alternateFullName,
            fullNameConflict,
            personId,
            alternatePersonId,
            personIdConflict,
            personIdEmpty,
            currentMarket,
            resultingMarket,
            marketWarning,
            marketError,
            internalDuplicate,
            dealer: entry.dealer || "",
            errors:
              "" +
              (internalDuplicate ? "Internal Duplicate\n" : "") +
              (emailGood ? "" : "Invalid Email\n"),
            warnings: (fullNameConflict || personIdConflict) ?
              <>
                {fullNameConflict ? <>Full Name conflict.<br /> Existing name:<br />&quot;{alternateFullName}&quot;<br />will be replaced.<br /></> : null}
                {personIdConflict ? <> {settings.personIdFieldName} conflict. <br /> Existing {settings.personIdFieldName}: <br /> &quot;{alternatePersonId}&quot; <br />  will be replaced. </> : null}
              </>
              : null
          };

          newEntry.errorWarningSortData =
            (newEntry.errors ? -2e12 : 0) +
            (newEntry.warnings ? -2e8 : 0) +
            newEntry.row;

          calculated.push(newEntry);
        }
      });
    }
    bulkAddData = {
      items: calculated,
      status: status
    };
  };
  calcBulkAddData();

  let reactTable = React.useRef(null);

  const classes = useStyles();
  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={event => {
          console.log("Got event: " + event.target.files[0]);
          setRawImportData(null);
          setWarningsAcknowledged(new Set());
          setShowBulkAdd(true);

          const file = event.target.files[0];
          let reader = new FileReader();
          reader.readAsBinaryString(file, "UTF-8");
          event.target.value = ""; // Reset for next time
          reader.onload = function (evt) {
            const workbook = XLSX.read(evt.target.result, { type: "binary" });

            let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });

            let modifiedData = [];
            data.forEach((row, rowIndex) => {
              if (row[0] || row[1] || row[2]) {
                // Skipping full blank rows
                modifiedData.push({
                  row: rowIndex + 1,
                  email: row[0],
                  fullName: row[1],
                  dealer: row[2],
                  personId: row[3]
                });
              }
            });

            setRawImportData({ items: modifiedData, fileName: file.name });
            //console.log("Got data: "+JSON.stringify(modifiedData));
          };
          reader.onerror = function (evt) {
            console.log("Error loading " + file);
          };
          /* convert from workbook to array of arrays */
        }}
      />
      <Dialog
        open={!!showBulkAdd}
        onClose={() => {
          setShowBulkAdd(false);
          setDoingBulkAdd(null);
        }}
        fullWidth={true}
        maxWidth={false}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="bulk-add-dialog">
          <CloseIcon
            style={{ float: "right" }}
            fontSize="large"
            onClick={() => {
              setShowBulkAdd(false);
              setDoingBulkAdd(null);
            }}
          />
        </DialogTitle>{" "}
        <DialogContent>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <GroupAddIcon />
                  </CardIcon>
                  <div style={{ display: "grid" }}>
                    <h1 className={classes.cardIconTitle}>Bulk Import</h1>
                    <h2
                      sytle={{ marginTop: "20px" }}
                      className={classes.cardIconTitle}
                    >
                      from:{" "}
                      <small>
                        <i>
                          {rawImportData && rawImportData.fileName
                            ? rawImportData.fileName
                            : ""}
                        </i>
                      </small>{" "}
                      <span style={{ float: "right", marginRight: "30%" }}>
                        to:{" "}
                        <OrganizationChooser
                          disabled={!!doingBulkAdd}
                          availableOrganizations={allAllowedMarkets}
                          currentOrganization={selectedMarket}
                          onOrganizationChange={newValue => {
                            setSelectedMarket(newValue);
                          }}
                        />
                      </span>
                    </h2>
                  </div>
                </CardHeader>
                <CardBody>
                  {doingBulkAdd ? (
                    <>
                      <Typography>Bulk Adding Students</Typography>
                      <br />
                      <LinearProgress
                        style={{ width: "100%" }}
                        variant={"determinate"}
                        value={doingBulkAdd.percent}
                      />
                      <br />
                      {doingBulkAdd.message}
                      <br />
                    </>
                  ) : bulkAddData && bulkAddData.items ? (
                    <div
                      style={
                        {
                          //    overflowY: 'scroll',
                          //maxHeight: '50vh'
                        }
                      }
                    >
                      <ReactTable
                        data={bulkAddData.items}
                        style={{
                          height: "calc( 100vh - 550px)"
                        }}
                        columns={[
                          {
                            Header: "",
                            accessor: "errorWarningSortData",
                            width: 100,
                            Cell: row => [
                              row.original.errors ? (
                                <Tooltip
                                  key={"error_" + row.original.row}
                                  title={row.original.errors}
                                >
                                  <ErrorIcon
                                    style={{ float: "left", color: "red" }}
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              ),
                              row.original.warnings ? (
                                <span
                                  key={"warning_" + row.original.row}
                                  style={{ float: "right" }}
                                >
                                  <Tooltip title={row.original.warnings}>
                                    <WarningIcon
                                      style={
                                        warningsAcknowledged.has(
                                          row.original.email
                                        )
                                          ? { color: "LightGray" }
                                          : { color: "orange" }
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    title={"Acknowledge Warning"}
                                    style={{ float: "left" }}
                                  >
                                    <Checkbox
                                      style={{ float: "right", padding: "0px" }}
                                      color="primary"
                                      checked={warningsAcknowledged.has(
                                        row.original.email
                                      )}
                                      onChange={e => {
                                        addRemoveWarningsAcknowledged(
                                          e.target.checked,
                                          row.original.email
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              ) : (
                                ""
                              ),
                              row.original.marketError ? (
                                <Tooltip
                                  key={"market_error_" + row.original.row}
                                  title={row.original.marketError}
                                >
                                  <ErrorIcon
                                    style={{ float: "left", color: "red" }}
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              ),
                              row.original.marketWarning ? (
                                <span
                                  key={"market_warning_" + row.original.row}
                                  style={{ float: "right" }}
                                >
                                  <Tooltip title={row.original.marketWarning}>
                                    <WarningIcon
                                      style={
                                        marketWarningsAcknowledged.has(
                                          row.original.email
                                        )
                                          ? { color: "LightGray" }
                                          : { color: "orange" }
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    title={"Acknowledge Market Warning"}
                                    style={{ float: "left" }}
                                  >
                                    <Checkbox
                                      style={{ float: "right", padding: "0px" }}
                                      color="primary"
                                      checked={marketWarningsAcknowledged.has(
                                        row.original.email
                                      )}
                                      onChange={e => {
                                        addRemoveMarketWarningsAcknowledged(
                                          e.target.checked,
                                          row.original.email
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              ) : (
                                ""
                              )
                            ]
                          },
                          {
                            Header: "Row",
                            accessor: "row",
                            width: 100
                          },
                          {
                            Header: "Email",
                            accessor: "email",
                            getProps: (state, rowInfo, column) => {
                              if (!rowInfo) {
                                return {};
                              }
                              return {
                                style: rowInfo.original.errors
                                  ? { textShadow: "0px 0px 20px red" }
                                  : {}
                              };
                            },
                            Cell: row => <Typography>{row.value}</Typography>
                          },
                          {
                            Header: "Name",
                            accessor: "fullName",
                            getProps: (state, rowInfo, column) => {
                              if (!rowInfo) {
                                return {};
                              }
                              return {
                                style:
                                  rowInfo.original.fullNameConflict &&
                                    !warningsAcknowledged.has(
                                      rowInfo.original.email
                                    )
                                    ? { textShadow: "0px 0px 20px orange" }
                                    : {}
                              };
                            },
                            Cell: row => <Typography>{row.value}</Typography>
                          },
                          {
                            Header: selectedMarket
                              ? "Target Market"
                              : "Current Market",
                            accessor: "resultingMarket",
                            getProps: (state, rowInfo, column) => {
                              if (!rowInfo) {
                                return {};
                              }
                              return {
                                style: rowInfo.original.marketError
                                  ? { textShadow: "0px 0px 20px red" }
                                  : rowInfo.original.marketWarning &&
                                    !marketWarningsAcknowledged.has(
                                      rowInfo.original.email
                                    )
                                    ? { textShadow: "0px 0px 20px orange" }
                                    : {}
                              };
                            },
                            Cell: row => (
                              <Typography>
                                {marketDataById[row.value]
                                  ? marketDataById[row.value].name
                                  : "---"}
                              </Typography>
                            )
                          },
                          {
                            Header: "Dealer",
                            accessor: "dealer",
                            Cell: row => <Typography>{row.value}</Typography>
                          },
                          {
                            Header: settings.personIdFieldName,
                            accessor: "personId",
                            getProps: (state, rowInfo, column) => {
                              if (!rowInfo) {
                                return {};
                              }
                              return {
                                style:
                                  rowInfo.original.personIdConflict &&
                                    !warningsAcknowledged.has(
                                      rowInfo.original.email
                                    )
                                    ? { textShadow: "0px 0px 20px orange" }
                                    : (
                                      rowInfo.original.personIdEmpty
                                        ? {
                                          color: "gray",
                                          fontStyle: 'italic'
                                        }
                                        : {}
                                    )
                              };
                            },
                            Cell: row => <Typography>{row.value}</Typography>
                          }
                        ]}
                        defaultSorted={[
                          {
                            id: "errorWarningSortData",
                            desc: false
                          }
                        ]}
                        pageSize={Math.max(
                          10,
                          bulkAddData && bulkAddData.items
                            ? bulkAddData.items.length
                            : 0
                        )}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                        NoDataComponent={() => {
                          return (
                            <CircularProgress
                              disableShrink
                              style={{
                                position: "absolute",
                                top: "250px",
                                // Center it left/right:
                                left: "0px",
                                right: "0px",
                                marginLeft: "auto",
                                marginRight: "auto"
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </CardBody>
                <CardActionArea></CardActionArea>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <i style={{ float: "right" }}>
            Add All button is availabe when there are no errors, and all
            warnings have been acknowledged.
          </i>
          <FormControlLabel
            style={{ float: "left", marginLeft: "50px" }}
            control={
              <Checkbox
                name="checkedC"
                color="primary"
                disabled={!!doingBulkAdd}
                checked={skipHeader}
                onChange={event => {
                  setSkipHeader(!!event.target.checked);
                }}
              />
            }
            label="Skip Header"
          />

          <Button
            style={{ margin: "25px" }}
            color="primary"
            disabled={!okayToAddAll() || doingBulkAdd}
            onClick={() => {
              handleBulkImport();
            }}
          >
            Add All
          </Button>
          <Button
            style={{ margin: "25px" }}
            onClick={() => {
              setDoingBulkAdd(null);
              setShowBulkAdd(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
