import React, { useContext } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-6";

import { useQuery, useMutation, useSubscription } from "@apollo/client";

import Datetime from "react-datetime";

import settings from "../../aws-exports.json";

import { useHistory } from "react-router-dom";

import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js";

import XLSX from "xlsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import Fab from "@material-ui/core/Fab";

import DateFnsUtils from "@date-io/moment"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import AddCircle from "@material-ui/icons/AddCircle";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ClassIcon from "@material-ui/icons/Class";
import CarIcon from "@material-ui/icons/DriveEta";
import Table from "components/Table/Table.js";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import StudentSelector from "components/StudentSelector/StudentSelector.js";
import ClassEdit from "components/ClassTable/ClassEdit.js";
import Chip from "@material-ui/core/Chip";
import Queries from "GraphQL/InstructorAccess.js";
import BuildIcon from "@material-ui/icons/Build";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";

import distiAuth from "disti-auth.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import util from "util.js";

import "./class-table.css";

const supportChatSetting = true; //window.location.search.includes("phase3=88")

const supportVideoCapture = true; //window.location.search.includes("phase3=99")

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const chipPrefix = ( lessonMods, contentID ) => {
  const parsedClassLessonMods = JSON.parse(lessonMods);
  
  for (let classKey in parsedClassLessonMods) {
    if(classKey == contentID) {
      return "*";
    }
  }

  return "";
}

const getTimeZoneOptions = showTimezoneOffset => {
  const timeZones = moment.tz.names();
  const offsetTmz = [];

  for (const i in timeZones) {
    const tz = timeZones[i];
    const tzOffset = moment.tz(tz).format("Z");
    const value =
      "" +
      parseInt(
        tzOffset
          .replace(":00", ".00")
          .replace(":15", ".25")
          .replace(":30", ".50")
          .replace(":45", ".75")
      ).toFixed(2);

    const timeZoneOption = {
      label: showTimezoneOffset ? `${tz} (GMT${tzOffset})` : tz,
      value
    };
    offsetTmz.push(timeZoneOption);
  }

  return offsetTmz;
};
const timeZoneOptions = getTimeZoneOptions(true);
const timeZoneGuess = moment.tz.guess();
const timeZoneGuessIndex = timeZoneOptions.findIndex(a => {
  return a.label.startsWith(timeZoneGuess);
});
console.log("timeZoneGuess: " + timeZoneGuess);

const useStyles = makeStyles(styles);

const defaultFilterMethod = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== undefined) {
    const haystack = String(row[id]).toLowerCase();
    const needle = String(filter.value).toLowerCase();
    return haystack.includes(needle);
  }
  return true;
};

const contentFilter = (filter, row, column) => {
  /* Filter by an array of strings */
  const id = filter.pivotId || filter.id;
  if (row[id] !== undefined) {
    const arrayToSearch = row[id];
    if (!Array.isArray(arrayToSearch)) {
      return false;
    }
    const needle = String(filter.value).toLowerCase();
    return arrayToSearch.some(item => {
      const haystack = String(item).toLowerCase();
      if (haystack.includes(needle)) {
        return true;
      }
    });
  }
  return true;
};
const DefaultColumnFilter = ({ filter, onChange }) => {
  return (
    <input
      value={filter ? filter.value : ""}
      onChange={e => {
        onChange(e.target.value || []); // Set undefined to remove the filter entirely
      }}
      placeholder={`🔍`} //Search ${count} records...`}
    />
  );
};
const defaultSorted = [
  {
    id: "startDate",
    desc: false
  },
  {
    id: "name",
    desc: false
  }
];
const columns = [
  {
    Header: "Name",
    accessor: "name",
    Filter: DefaultColumnFilter
  },
  {
    Header: "Market",
    accessor: "marketName",
    Filter: DefaultColumnFilter,
    sortable: true,
    filterable: true,
    width: 125
  },
  {
    Header: "Webcam",
    accessor: "useWebcam",
    Filter: DefaultColumnFilter,
    sortable: false,
    filterable: false,
    width: 75
  }
]
  .concat(
    supportVideoCapture
      ? [
          {
            Header: "Capture Video",
            accessor: "captureVideo",
            Filter: DefaultColumnFilter,
            sortable: false,
            filterable: false,
            width: 75
          }
        ]
      : []
  )
  .concat(
    supportChatSetting
      ? [
          {
            Header: "Chat",
            accessor: "showChat",
            Filter: DefaultColumnFilter,
            sortable: false,
            filterable: false,
            width: 75
          }
        ]
      : []
  )
  .concat([
    {
      Header: "Report Results",
      accessor: "reportResults",
      Filter: DefaultColumnFilter,
      sortable: false,
      filterable: false,
      width: 75
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      filterable: false,
      width: 120,
      Cell: props => (
        <span
          style={
            props.original.isActive
              ? {
                  background: "lightgreen",
                  borderRadius: "20px",
                  padding: "5px"
                }
              : {}
          }
        >
          {props.value ? moment.unix(props.value).format("DD MMM YYYY") : "?"}
        </span>
      )
    },
    {
      Header: "End Date",
      accessor: "endDate",
      filterable: false,
      width: 120,
      Cell: props => (
        <span
          style={
            props.original.isActive
              ? {
                  background: "lightgreen",
                  borderRadius: "20px",
                  padding: "5px"
                }
              : {}
          }
        >
          {props.value ? moment.unix(props.value).format("DD MMM YYYY") : "?"}
        </span>
      )
    },
    {
      Header: "Start Time",
      accessor: "dailyStartTime",
      filterable: false,
      width: 75
    },
    {
      Header: "End Time",
      accessor: "dailyEndTime",
      filterable: false,
      width: 75
    },
    {
      Header: "Students",
      accessor: "students",
      sortable: true,
      filterable: false,
      width: 100,
      style: { textAlign: "center" }
    },
    {
      Header: "Content",
      accessor: "content",
      Filter: DefaultColumnFilter,
      filterMethod: contentFilter,
      Cell: row => 
        <span>
        <>
          {
            row.value
            ? row.value.map(({ name, id, allowedInMarket }) => {
                return name ? (
                  <Chip
                    style={{
                      ...(!allowedInMarket ? { background: "red" } : {}),
                      maxWidth: "140px"
                    }}
                    size="small"
                    key={"content_chip" + id}
                    label={(row.original.lessonMods ? chipPrefix(row.original.lessonMods, id) : "") + name}
                  />
                ) : null;
              })
            : "" 
          }
        </>
        </span>
        ,
      sortable: false,
      filterable: false,
      width: 150
    },
    {
      Header: "Region",
      accessor: "region",
      Filter: DefaultColumnFilter,
      filterable: true,
      width: 100
    },
    {
      accessor: "actions",
      sortable: false,
      filterable: false,
      width: 100
    }
  ]);
const tableStyle = {
  height: "calc( 100vh - 276px)"
}
const styleFloatRight = { float: "right" }
const marginLeft20 = { marginLeft: "20px" }
export default function ClassTable2() {
  const [nowTime, setNowTime] = React.useState(moment().unix());
  React.useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setNowTime(moment().unix());
    }, 3000); // TODO: Make this a longer interval
    return () => clearInterval(interval);
  });

  const { allowedActions, assignedMarkets } = React.useContext(
    distiAuth.AllowedActionsContext
  );

  let reactTable = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(null);

  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const [menuTargetData, setMenuTargetData] = React.useState(null);

  const reduceTimeFromIndex = (oldValue, newIndex) => {
    let entry = timeZoneOptions[newIndex];
    let shortName = entry.label.match(/[^()]+/g)[1];
    return {
      index: newIndex,
      fullTimeName: entry.label,
      shortName: shortName,
      offset: parseFloat(entry.value)
    };
  };
  const [currentTimeOffset, setCurrentTimeOffset] = React.useReducer(
    reduceTimeFromIndex,
    reduceTimeFromIndex(0, timeZoneGuessIndex)
  );

  const { loading: contentLoading, data: contentData } = useQuery(
    Queries.CONTENT.ALL
  );

  let {
    loading: queryLoading,
    data: queryData
  } = SubscriptionHelpers.useSubscribedClassQuery(); //useQuery(Queries.CLASSES.ALL);

  let {
    loading: modsLoading,
    data: modsData
  } = useQuery( Queries.LESSON_MOD.ALL );

  const [
    deleteItem,
    { loading: deleteLoading, error: deleteError }
  ] = useMutation(Queries.CLASSES.DELETE);

  const [
    createItem,
    { loading: createLoading, error: createError }
  ] = useMutation(Queries.CLASSES.CREATE);

  const [
    modifyItem,
    { loading: modifyLoading, error: modifyError }
  ] = useMutation(Queries.CLASSES.MODIFY);

  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL);

  const onFilteredChange = React.useCallback(() => {
    if (reactTable.current) {
      setPageSize(reactTable.current.getResolvedState().sortedData.length);
    }
  }, [setPageSize,reactTable]);

  const classQueryDataItems = React.useMemo(() => {
    return queryData && queryData.listClasses
      ? queryData.listClasses.items
      : [];
  }, [
    queryData && queryData.listClasses
      ? JSON.stringify(queryData.listClasses.items)
      : "",
    queryLoading
  ]);

  const handlePopupMenuForId = React.useCallback((event, id) => {
    setMenuAnchorElement(event.currentTarget);
    const classItem = classQueryDataItems.find(item => item.id == id);
    setMenuTargetData(classItem);
  },[classQueryDataItems,setMenuAnchorElement,setMenuTargetData]);
  const errorSub = false;

  let allMarkets = [];
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items) {
    allMarkets = [...marketQueryData.listMarkets.items];
    marketQueryData.listMarkets.items.forEach(item => {
      marketDataById[item.id] = item;
    });
  }

  if (errorSub) {
    console.log("Got errorSub: " + JSON.stringify(errorSub));
  }
  if (deleteError) {
    console.log("Mutate error: " + JSON.stringify(deleteError));
  }
  async function doDelete(item) {
    try {
      console.log("onDeleteClassHandler: id: " + JSON.stringify(item.id));

      const isConfirmed = window.confirm(
        "Are you sure you want to delete class with name: " +
          item.description +
          "?"
      );

      if (isConfirmed) {
        await deleteItem({ variables: { pk: item.id } });
        console.log("Success with delete class");
      } else {
        console.log("Did not delete.");
      }
    } catch (e) {
      console.log("Problem in delete: " + e);
    }
  }
  async function doCreate() {
    try {
      setCreatingNewClass(true);
      setEditClass({});
    } catch (e) {
      console.log("Problem in create: " + e);
    }
  }
  let startDateChanged = async function(mom, item) {
    try {
      console.log("Got moment: " + JSON.stringify(mom));
      item.startDate = moment(mom).unix();
      console.log("new date: " + item.startDate);

      await modifyClassItem(item);
      console.log("Updated.");
    } catch (e) {
      console.log("Problem updating date: " + e);
    }
  };
  let endDateChanged = async function(mom, item) {
    try {
      item.endDate = moment(mom).unix();

      await modifyClassItem(item);
    } catch (e) {
      console.log("Problem updating date: " + e);
    }
  };
  const normalizeSecondsFromStartOfDay = t => {
    const secondsInADay = 60 * 60 * 24;
    let rval = t % secondsInADay;
    if (rval < 0) {
      rval += secondsInADay;
    }
    if (rval > secondsInADay) {
      rval -= secondsInADay;
    }
    return rval;
  };

  let dailyStartTimeChanged = async function(secondsIntoTheDay, item) {
    try {
      item.dailyStartTime = normalizeSecondsFromStartOfDay(secondsIntoTheDay);

      await modifyClassItem(item);
    } catch (e) {
      console.log("Problem updating time: " + e);
    }
  };
  let dailyEndTimeChanged = async function(secondsIntoTheDay, item) {
    try {
      item.dailyEndTime = normalizeSecondsFromStartOfDay(secondsIntoTheDay);

      await modifyClassItem(item);
    } catch (e) {
      console.log("Problem updating time: " + e);
    }
  };

  const modifyClassItem = React.useCallback( async function(item) {
    console.log("Before mod: " + JSON.stringify(item));
    // Make sure it is fully formed
    item.startDate |= 0;
    item.endDate |= 0;
    item.dailyStartTime |= 0;
    item.dailyEndTime |= 0;
    item.dailyEndTime |= 0;
    item.region = item.region ? item.region : settings.CoreRegion;
    item.responsibleParty = "results";
    if (!item.content) item.content = [];
    if (!item.students) item.students = [];
    item.market = item.market || "";
    item.useWebcam = Boolean(item.useWebcam);
    item.hideChat = Boolean(item.hideChat);
    item.contentSettings = item.contentSettings || "";
    item.singleLesson = Boolean(item.singleLesson);
    item.lessonMods = item.lessonMods || "{}";

    let vars = { pk: item.id, ...item };
    console.log("vars: " + JSON.stringify(vars));

    await modifyItem({ variables: vars }); //description: item.description, startDate: item.startDate, endDate: item.endDate}});
  },[modifyItem]);

  // Looks up the name of content by ID.
  const contentNameById = function(id) {
    if (contentLoading || !contentData) {
      return "...";
    }
    let filteredItems = contentData.listContent.items.filter(val => {
      return val.id == id;
    });
    if (filteredItems.length === 0) {
      return "Unknown";
    } else {
      return filteredItems[0].name;
    }
  };
  const contentIdInClassItem = function(contentId, classItem) {
    try {
      return !!(classItem.content || []).find(id => contentId == id);
    } catch (e) {
      return false;
    }
  };
  const clampNumber = (num, lowNum, highNum) => {
    num = 0 + num; // Ensuring that num is a number
    return Math.max(Math.min(num, highNum), lowNum);
  };
  const regionNameById = regionId => {
    const item = distiAuth
      .getRegionDataItems()
      .find(item => item.id == regionId);
    return item ? item.name : regionId || "";
  };
  const handleCloseMenu = React.useCallback(() => {
    setMenuAnchorElement(null);
    setMenuTargetData(null);
  }, [setMenuAnchorElement, setMenuTargetData]);

  const allClassList = React.useMemo(() => {
    const assignedMarketsSet = new Set(assignedMarkets);

    const rval = classQueryDataItems
      .filter(classItem => {
        return (
          allowedActions.noMarketRestrictions ||
          assignedMarketsSet.has(classItem.market)
        );
      })
      .map(classItem => {
        const isActive =
          classItem.startDate <= nowTime && nowTime <= classItem.endDate;
        const id = classItem.id;
        return {
          id: id,
          isActive,
          name: classItem.description,
          useWebcam: (
            <Checkbox
              disabled
              key={classItem.id}
              color="primary"
              checked={Boolean(classItem.useWebcam)}
            />
          ),
          captureVideo: (
            <Checkbox
              disabled
              key={classItem.id}
              color="primary"
              checked={Boolean(classItem.captureVideo)}
            />
          ),
          // Notice the reversing of sense for hideChat vs showChat.  We want to show the user correctly but need to default to false for upgrade path
          showChat: (
            <Checkbox
              disabled
              key={classItem.id}
              color="primary"
              checked={!classItem.hideChat}
            />
          ),
          reportResults: (
            <Checkbox
              disabled
              key={classItem.id}
              color="primary"
              checked={!classItem.suppressReportResults}
            />
          ),
          students: classItem.students ? classItem.students.length : 0,
          content: classItem.content
            ? classItem.content.map((content, index) => ({
                name: contentNameById(content),
                id: content,
                allowedInMarket: marketDataById[classItem.market]
                  ? (marketDataById[classItem.market].content || []).includes(
                      content
                    )
                  : true
              }))
            : "",
          lessonMods: classItem.lessonMods,
          lessonModInfo: modsData,
          startDate: classItem.startDate,
          endDate: classItem.endDate,
          dailyStartTime: moment
            .unix(
              parseInt(classItem.dailyStartTime) +
                currentTimeOffset.offset * 60 * 60
            )
            .utc()
            .format("HH:mm"),
          dailyEndTime: moment
            .unix(
              parseInt(classItem.dailyEndTime) +
                currentTimeOffset.offset * 60 * 60
            )
            .utc()
            .format("HH:mm"),
          marketName: marketDataById[classItem.market]
            ? marketDataById[classItem.market].name
            : "---",
          region: regionNameById(classItem.region) || classItem.region,
          actions: (
            <Button
              disabled={
                !allowedActions.createClasses &&
                !allowedActions.viewLiveStudentSessions
              }
              key={classItem.id}
              round
              size="sm"
              color="primary"
              onClick={
                event => {
                  handlePopupMenuForId(event, id);
                }
                // setMenuAnchorElement(event.currentTarget);
                // setMenuTargetData(classItem);
                //}
              }
            >
              <BuildIcon />
              <ArrowDropDownIcon />
            </Button>
          )
        };
      });
    return rval;
  }, [
    classQueryDataItems,
    allowedActions.noMarketRestrictions,
    contentLoading,
    currentTimeOffset.offset,
    JSON.stringify(marketDataById),
    JSON.stringify(modsData)
  ]);

  const dumpAllClassData = () => {
    const details = allClassList.map(item => {
      const origData = classQueryDataItems.find(c => c.id == item.id);
      return {
        name: item.name,
        content: (item.content.name || []).join("\r\n"),
        market: item.marketName,
        region: item.region,
        students: (origData.students || []).join("\r\n")
      };
    });
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(details);
    XLSX.utils.book_append_sheet(book, sheet, "Classes");
    XLSX.writeFile(book, "class-data.xlsx");
  };

  const onTimeZoneChange = index => {
    console.log("onTimeZoneChange: " + index);
    setCurrentTimeOffset(index);
  };
  const [editClass, setEditClass] = React.useState(null);
  const [creatingNewClass, setCreatingNewClass] = React.useState(false);

  const NoDataComponent = React.useCallback(() => {
    return queryLoading ? (
      <CircularProgress
        disableShrink
        style={{
          position: "absolute",
          top: "200px",
          // Center it left/right:
          left: "0px",
          right: "0px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      />
    ) : (
      <div
        style={{
          position: "absolute",
          top: "200px",
          // Center it left/right:
          textAlign: "center",
          left: "0px",
          right: "0px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        No Classes Visible
      </div>
    );
  },[queryLoading]);

  const history = useHistory();

  const onDeleteClass = React.useCallback(()=>{
    /* TODO doDelete */
  })

  const onApplyClass = React.useCallback(async update => {
    try {
      if (creatingNewClass) {
        const newClass = await createItem();
        update.id = newClass.data.createClass.pk;
      }
      modifyClassItem(update);
    } catch (e) {
      console.log("Problem creating class: " + e);
      window.alert("Unable to create class");
    }
  },[creatingNewClass,modifyClassItem, createItem]);

  const onCloseClass = React.useCallback(() => setEditClass(null),[setEditClass])

  const onTimeZoneChangeCallback = React.useCallback((name, entry) => {
    onTimeZoneChange(entry.props.value);
  },[onTimeZoneChange]);

  const timzeZoneMenuItems = React.useMemo(()=> timeZoneOptions.map((item, index) => {
    return (
      <MenuItem key={index} value={index}>
        {item.label}
      </MenuItem>
    );
  }), [JSON.stringify(timeZoneOptions)]);

  let accessObj = { access: {} };
  const classes = useStyles();
  return (
    <>
      {editClass ? (
        <ClassEdit
          createNew={creatingNewClass}
          initialValue={editClass}
          timesShownAsText={timeZoneOptions[currentTimeOffset.index].label}
          timesShowAsOffset={currentTimeOffset.offset}
          //onDelete={onDeleteClass}
          onApply={onApplyClass}
          onClose={onCloseClass}
        />
      ) : (
        ""
      )}

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <GridContainer>
          <StudentSelector
            accessObj={accessObj}
            onAppy={classItem => {
              modifyClassItem(classItem);
            }}
          />

          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <ClassIcon />
                </CardIcon>
                <h1 className={classes.cardIconTitle}>
                  Classes
                  {allowedActions.createClasses ? (
                    <Button
                      round
                      color="primary"
                      onClick={() => {
                        doCreate();
                      }}
                      style={styleFloatRight}
                    >
                      <AddCircle />
                      Create Class
                    </Button>
                  ) : (
                    ""
                  )}
                  {allowedActions.exportTables ? (
                    <Button
                      round
                      color="primary"
                      style={styleFloatRight}
                      onClick={dumpAllClassData}
                    >
                      <GetAppIcon />
                      Export Table
                    </Button>
                  ) : null}
                </h1>
                <div
                  style={{
                    width: "60%",
                    textAlign: "right",
                    position: "absolute",
                    top: "3em",
                    color: "grey"
                  }}
                >
                  Times shown as:
                  <Select
                    style={marginLeft20}
                    value={currentTimeOffset.index || 0}
                    onChange={onTimeZoneChangeCallback}
                  >
                    {timzeZoneMenuItems}
                  </Select>
                </div>
              </CardHeader>
              <CardBody>
                <Menu
                  id="action-menu"
                  anchorEl={menuAnchorElement}
                  open={Boolean(menuAnchorElement)}
                  onClose={handleCloseMenu}
                >
                  {allowedActions.createClasses ? (
                    <MenuItem
                      onClick={() => {
                        setCreatingNewClass(false);
                        setEditClass(menuTargetData);
                        handleCloseMenu();
                      }}
                    >
                      Edit Class
                    </MenuItem>
                  ) : null}
                  {allowedActions.viewLiveStudentSessions ? (
                    <MenuItem
                      disabled={
                        !menuTargetData ||
                        !(
                          menuTargetData.startDate <= nowTime &&
                          nowTime <= menuTargetData.endDate
                        )
                      }
                      onClick={() => {
                        handleCloseMenu();
                        distiAuth.globalInstructorSelectedClassId =
                          menuTargetData.id;
                        history.push("/admin/facilitator-dashboard");
                      }}
                    >
                      Facilitate Class
                    </MenuItem>
                  ) : null}
                  {allowedActions.createClasses ? (
                    <MenuItem
                      onClick={() => {
                        doDelete(menuTargetData);
                        handleCloseMenu();
                      }}
                    >
                      Delete Class
                    </MenuItem>
                  ) : null}
                </Menu>
                <ReactTable
                  ref={r => {
                    reactTable.current = r;
                  }}
                  data={allClassList}
                  style={tableStyle}
                  filterable
                  defaultFilterMethod={defaultFilterMethod}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  onFilteredChange={onFilteredChange}
                  pageSize={pageSize != null ? pageSize : allClassList.length}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  NoDataComponent={NoDataComponent}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </MuiPickersUtilsProvider>
    </>
  );
}
