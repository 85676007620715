import React from "react";
import ReactTable from "react-table-6";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardActions from "@material-ui/core/CardActions";
import CardBody from "components/Card/CardBody.js";
import CardContent from "@material-ui/core/CardContent";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import distiAuth from "disti-auth";
import EditIcon from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import Queries from "GraphQL/InstructorAccess.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { initial } from "lodash";


const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    entryLine: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px"
    }
});
  
export default ({
    open,
    onApply,
    onClose,
    initialValue,
}) => {
    if (!open) {
        return "";
    }

    const { allowedActions, assignedMarkets } = React.useContext(
    distiAuth.AllowedActionsContext
    );

    const assignedMarketsSet = new Set(assignedMarkets);
    
    const { loading: marketLoading, data: marketQueryData } = useQuery(Queries.MARKETS.ALL);
    const [markets, setMarkets] = React.useState(initialValue.availableMarkets || []);
    
    let marketDataById = {};
    let sortedAllMarkets = [];
    if (marketQueryData && marketQueryData.listMarkets.items) {
      marketQueryData.listMarkets.items.forEach(item => {
          marketDataById[item.id] = item;
        });
        
        sortedAllMarkets = [...marketQueryData.listMarkets.items].sort((a, b) => {
            const nameA = (a.name || "").toUpperCase();
            const nameB = (b.name || "").toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    }
    
    let tableData = [];
    tableData.push(initialValue);
    let reactTable = React.useRef(null);
  
    const allMarketIds = sortedAllMarkets.map(i => i.id);
    const availableMarketIds = allowedActions.noMarketRestrictions
      ? allMarketIds
      : allMarketIds.filter(item => assignedMarketsSet.has(item));
  
    const uneditableMarketSet = markets
      ? allowedActions.noMarketRestrictions
        ? new Set()
        : new Set(markets.filter(item => !assignedMarketsSet.has(item)))
      : new Set();      
    
    const classes = useStyles();

    const handleCancel = () => {
        onClose();
    };
    const handleApply = () => {
        onApply({
            pk: initialValue.pk,
            dateCreated: initialValue.dateCreated,
            marketCreated: initialValue.marketCreated,
            content: initialValue.content,
            valid: initialValue.valid,
            availableMarkets: markets,
            name: initialValue.name,
            zipGuid: initialValue.zipGuid
        });
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">
            <CloseIcon
                style={{ position: "absolute", right: "25px", zIndex: "1" }}
                fontSize="large"
                onClick={handleCancel}
            />
            </DialogTitle>
            <DialogContent>
            <GridContainer>
            <GridItem xs={12}>
                <Card className={classes.root}>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                    <EditIcon />
                    </CardIcon>
                    <h1 className={classes.cardIconTitle}>{"Lesson Modification Assignment to Markets"}</h1>
                </CardHeader>

                <CardContent>
                    <Typography color="textSecondary" variant="h5" component="h2">
                        <Autocomplete
                            multiple
                            disabled={!allowedActions.assignTrainerToMarket}
                            disableClearable={true}
                            id="tags-standard"
                            options={availableMarketIds}
                            getOptionLabel={id => {
                            return marketDataById[id]
                                ? marketDataById[id].name
                                : "---";
                            }}
                            value={markets}
                            onChange={(event, newValue) => {
                            // Don't allow for changing markets which we don't control
                            const newValueSet = new Set(newValue);
                            uneditableMarketSet.forEach(item =>
                                newValueSet.add(item)
                            );
                            setMarkets(Array.from(newValueSet));
                            }}
                            renderInput={params => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Available in Markets"
                            />
                            )}
                        />
                    </Typography>
                    <br />
                    <ReactTable
                        ref={r => {
                        reactTable.current = r;
                        }}
                        data={tableData}
                        columns={[
                        {
                            Header: "Date Created",
                            accessor: "dateCreated",
                            sortable: false,
                            filterable: false,
                            Cell: row => (
                                <span>
                                {row.value ? moment.unix(row.value).format("DD MMM YYYY") : "?"}
                                </span>
                            )
                        },
                        {
                            Header: "Name",
                            accessor: "name",
                            sortable: false,
                            filterable: false,
                        },
                        {
                            Header: "Market Created",
                            accessor: "marketCreated",
                            sortable: false,
                            filterable: false,
                            Cell: row =>
                            row.value
                                ? <Chip
                                    style={{
                                    margin: "1px"
                                    }}
                                    label={
                                        marketLoading ? "..."
                                        : marketDataById[row.value]
                                        ? marketDataById[row.value].name
                                        : "Deleted Market"
                                    }
                                    key={row.value}
                                />
                                : ""
                        },
                        {
                            Header: "Content",
                            accessor: "contentName",
                            sortable: false,
                            filterable: false,
                        },
                        {
                            Header: "Valid",
                            accessor: "valid",
                            sortable: false,
                            filterable: false,
                            Cell: params =>
                              params.value ? ( <>Yes</> ) : ( <i>- - -</i> )
                        },
                        ]}
                        defaultSorted={[
                        {
                            id: "content",
                            desc: false
                        }
                        ]}
                        pageSize={ 1 }
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                    />
                </CardContent>

                <CardActions></CardActions>
                </Card>
            </GridItem>
            </GridContainer>
            <Button
                color="primary"
                style={{
                    float: "left",
                    marginLeft: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                onClick={handleApply}
            >
            Confirm
            </Button>
            <Button
                style={{
                    float: "right",
                    marginRight: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                onClick={handleCancel}
            >
            Cancel
            </Button>
            </DialogContent>
            </Dialog>
        </>
    );
}
