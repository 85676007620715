import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

import distiAuth from "disti-auth.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import PersonIcon from "@material-ui/icons/Person";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorIcon from "@material-ui/icons/Error";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import HistoryIcon from "@material-ui/icons/History";
import ListIcon from "@material-ui/icons/List";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

import Queries from "GraphQL/InstructorAccess.js";

import TrainingLog from "components/TrainingLog/TrainingLog.js";

import AWS from "aws-sdk";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

import moment from "moment";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import ReactPlayer from "react-player/lazy";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const readFileFromS3 = async ({ bucket, key }) => {
  const s3 = new AWS.S3({ region: process.env.AWS_REGION || "us-east-1" });

  try {
    const result = await s3
      .getObject({
        Bucket: bucket,
        Key: key
      })
      .promise()
      .then(data => {
        return data.Body;
      });
    const buf = new Buffer.from(result); //, 'binary');
    return buf.toString();
  } catch (e) {
    console.log("Problem in readFileFromS3.", e);
    throw "Problem reading file from S3";
  }
};

const useStyles = makeStyles(styles);
const useInterval = (callback, delay, startNow) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (startNow) {
      tick(); // Start with an immediate call
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

//let s3 = null;
const SingleVideoView = ({ region, video, autoplay }) => {
  const [url, setUrl] = React.useState(null);
  React.useEffect(() => {
    const client = new S3Client({
      region,
      credentials: AWS.config.credentials
    });
    const doIt = async () => {
      // Get https urls from the S3 links
      const command = new GetObjectCommand(video); // Has Bucket and Key
      const signedUrl = await getSignedUrl(client, command, {
        expiresIn: 60 * 60 * 2
      });
      setUrl(signedUrl + `#t=${video.startOffset},${video.endOffset}`);
    };
    doIt();
  }, [JSON.stringify(video)]);

  return (
    <div style={{}}>
      <div
        style={{
          position: "relative",
          paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
        }}
      >
        <ReactPlayer
          style={{
            position: "absolute",
            top: "0",
            left: "0"
          }}
          muted={true}
          playing={autoplay}
          controls={true}
          height={"100%"}
          width={"100%"}
          url={url}
        />
      </div>
    </div>
  );
};
const VideoBrowser = ({ videos }) => {
  if (videos && videos.releventVideos) {
    const firstObject = { first: true };
    try {
      return videos.releventVideos.map(v => {
        const rval = (
          <SingleVideoView
            autoplay={firstObject.first}
            key={v.Key}
            region={videos.region}
            video={v}
          />
        );
        firstObject.first = false;
        return rval;
      });
    } catch (e) {
      console.log("Problem:", e);
    }
  }
  return null;
};

const TimedLoadingButton = ({
  timeOfZeroProgress,
  timeOfFullProgress,
  failed,
  ...params
}) => {
  const deltaTime = timeOfFullProgress - timeOfZeroProgress;
  const [progress, setProgress] = React.useState(0);
  const [active, setActive] = React.useState(false);
  useInterval(
    () => {
      if (active) {
        return;
      }
      const now = Date.now() / 1000;
      const newProgress = Math.min(
        100,
        Math.round((100.0 * (now - timeOfZeroProgress)) / deltaTime)
      );
      setProgress(newProgress);
      if (newProgress >= 100) {
        setActive(true);
      }
    },
    3000,
    true
  );

  return (
    <Button {...{ ...params, disabled: !active || failed }}>
      {!active || failed ? (
        <span style={{ color: "white" }}>
          {failed ? "Failed\nprocessing" : progress + "%\nprocessed"}
        </span>
      ) : (
        <VideoLibraryIcon />
      )}
    </Button>
  );
};
const FancyVideoButton = ({ videos, onClick }) => {
  let processing = null;
  const sorted = [...videos]
    .filter(item => item.processing)
    .sort((a, b) => {
      // Get the worst case processing video
      if (Boolean(b.processingFailed) != Boolean(a.processingFailed))
        return b.processingFailed ? 1 : -1;
      return b.expectedProcessingFinishEpoch - a.expectedProcessingFinishEpoch;
    });
  processing = sorted[0];

  return videos.length ? (
    processing ? (
      <TimedLoadingButton
        round
        color="primary"
        onClick={onClick}
        failed={processing.processingFailed}
        timeOfZeroProgress={processing.processingStartTimeEpoch}
        timeOfFullProgress={processing.expectedProcessingFinishEpoch}
      ></TimedLoadingButton>
    ) : (
      <Button round color="primary" onClick={onClick}>
        <VideoLibraryIcon />
      </Button>
    )
  ) : null;
};

export default function StudentHistory({ username }) {
  const [showTrainingLogFile, setShowTrainingLogFile] = React.useState(null);
  const [showVideos, setShowVideos] = React.useState(null);

  const [_count, forceUpdate] = React.useReducer(x => x + 1, 0);
  const historyList = React.useRef([]);

  //  React.useEffect(() =>  {
  //	s3 = new AWS.S3();
  //  },[]);

  const gotSummary = (
    historyKey,
    summaryFile,
    summaryData,
    regionItem,
    s3ForRegion,
    videoFiles
  ) => {
    //console.log("Got summary: "+JSON.stringify(summaryFile, summaryData));
    if (true) {
      //summaryData.Body.type === "Buffer")
      const buf = new Buffer(summaryData.Body, "binary");
      const item = JSON.parse(buf.toString());
      try {
        const entry = historyList.current[historyKey];
        if (entry) {
          entry.countryCode = item.countryCode;
          entry.lesson = item.currentLesson;
          entry.content = item.currentContent;
          entry.percentComplete = item.percentComplete;
          entry.trainingMode = item.trainingMode;

          if (item.SubmissionDateAndTime) {
            entry.dateTime = item.SubmissionDateAndTime;
          } else {
            entry.dateTime = new Date(1000 * item.updateTime).toISOString();
          }

          entry.region = regionItem.name;

          if (item.ErrorCount) {
            const count =
              item.ErrorCount.AddedActions +
              item.ErrorCount.IncorrectActions +
              item.ErrorCount.MissedActions +
              item.ErrorCount.OutOfOrderActions;
            entry.totalErrors = count;
            entry.AddedActions = item.ErrorCount.AddedActions;
            entry.IncorrectActions = item.ErrorCount.IncorrectActions;
            entry.MissedActions = item.ErrorCount.MissedActions;
            entry.OutOfOrderActions = item.ErrorCount.OutOfOrderActions;
          }
          if (item.HintedActions) {
            entry.hints = item.HintedActions.length;
            entry.hintedActions = item.HintedActions;
          }
          if (item.score) {
            entry.score = "" + item.score + "%";
          } else if ("ActualScore" in item && "MaximumScore" in item) {
            try {
              entry.score =
                "" +
                Math.round((100.0 * item.ActualScore) / item.MaximumScore) +
                "%";
            } catch {
              entry.score = "Unavailable";
            }
          } else {
            entry.score = "---";
          }
          if (!("Pass" in item)) {
            entry.passed = "Not Submitted";
          } else if (item.processedTimeString || item.PassIsValid) {
            // We have actually processed the pass flag
            entry.passed = item.Pass ? (
              <CheckBoxIcon style={{ fill: "green" }} />
            ) : (
              <CheckBoxOutlineBlankIcon />
            );
          } else {
            entry.passed = "Not Calculated";
          }

          const releventVideos = [];
          const endTimeEpoch = moment.utc(entry.dateTime).unix();
          // Defaulting to 30 seconds elapsed if we don't have the time (it gets added after running for 1 minute)
          const s = (item.elapsedTime || "00:00:30").split(":");
          const elapsedTimeSeconds =
            parseInt(s[0]) * 60 * 60 + parseInt(s[1]) * 60 + parseFloat(s[2]);
          const startTimeEpoch = endTimeEpoch - elapsedTimeSeconds;

          // Find all the videos that apply to this particular session.
          // The videos can contain more than one lesson
          const fudge = 30; // seconds on each end to deal with potential time issues

          videoFiles.forEach(video => {
            /*
                            startTimeEpoch,
                            endTimeEpoch,
                            Key: item.Key,
                            Bucket: regionItem.reportsBucket,    */
            if (
              video.startTimeEpoch - fudge < startTimeEpoch &&
              video.endTimeEpoch + fudge > endTimeEpoch
            ) {
              // This video should cover this run
              let startOffset = startTimeEpoch - video.startTimeEpoch - 30;
              if (startOffset < 0) {
                startOffset = 0;
              }
              const endOffset = endTimeEpoch - video.startTimeEpoch + 3; // To show the screen that shows after "submit for grading"
              releventVideos.push({ ...video, startOffset, endOffset });
            }
          });
          const cb = () => {
            setShowVideos({
              region: regionItem.id,
              releventVideos: [...releventVideos],
              lesson: item.currentLesson,
              content: item.currentContent,
              trainingMode: item.trainingMode
            });
          };

          entry.viewVideos = (
            <FancyVideoButton videos={releventVideos} onClick={cb} />
          );

          entry.viewLog = entry.files["log.json"] ? (
            <Button
              round
              color="primary"
              onClick={() => {
                setShowTrainingLogFile({
                  logFile: entry.files["log.json"],
                  s3ForRegion: s3ForRegion,
                  regionItem: regionItem,
                  summaryData: [entry]
                });
              }}
            >
              <ListIcon />
            </Button>
          ) : "percentComplete" in item ? (
            Math.round(100 * item.percentComplete) + "% Complete"
          ) : (
            ""
          );

          forceUpdate();
        } else {
          console.log("Didn't find summaryFile: " + summaryFile);
        }
      } catch (e) {
        console.log("Issue in goSummary: " + e);
      }
    }
  };
  const gotList = async (dataContents, regionItem, s3ForRegion) => {
    //console.log("Got data: "+JSON.stringify(dataContents));

    const dataBySessionName = {};

    const videoFiles = [];

    for (const item of dataContents) {
      const s = item.Key.split("/");

      const fileName = s[3];
      const dirName = s[2];

      if ("classes" == dirName) {
        // We are only looking for the session data
        if (
          item.Key.endsWith("-content-video.mp4") ||
          item.Key.endsWith("-content-video.mp4.processing")
        ) {
          //Example: range-1617728389-1617728708-content-video.mp4
          try {
            const [, startTimeEpoch, endTimeEpoch] = item.Key.split("/")
              .pop()
              .split("-");

            const rval = {
              startTimeEpoch: parseInt(startTimeEpoch),
              endTimeEpoch: parseInt(endTimeEpoch),
              Key: item.Key,
              Bucket: regionItem.reportsBucket
            };
            if (item.Key.endsWith(".processing")) {
              console.log("PROCESSING: ", regionItem.reportsBucket, item.Key);
              rval.Key = item.Key.substring(
                0,
                item.Key.length - ".processing".length
              );

              const videoLength = rval.endTimeEpoch - rval.startTimeEpoch;

              const worstCaseProcessingRatio = 0.75; /* processing time per run time */

              // Read the .processing file to see when it started
              let processingStartTime = 0;
              try {
                processingStartTime = parseInt(
                  await readFileFromS3({
                    key: item.Key,
                    bucket: regionItem.reportsBucket
                  })
                );

                if (isNaN(processingStartTime)) {
                  throw "Bad processing start time";
                }
              } catch (e) {
                console.log(
                  "Problem getting processing start time from key:",
                  item.Key
                );
              }

              rval.processing = true;
              rval.expectedProcessingFinishEpoch = parseInt(
                processingStartTime + videoLength * worstCaseProcessingRatio
              );
              rval.processingStartTimeEpoch = processingStartTime;

              if (rval.expectedProcessingFinishEpoch < Date.now() / 1000) {
                // This video likely failed processing.  With this flag later code
                // can tell the user.
                rval.processingFailed = true;
              }
            }
            videoFiles.push(rval);
          } catch (e) {
            console.log("Problem processing:", item.Key, e);
          }
        }
      }

      const sessionName = regionItem.name + "#" + dirName;

      if (!dataBySessionName[sessionName]) {
        dataBySessionName[sessionName] = {
          dirName: dirName,
          region: regionItem.name,
          dateTime: "Loading...",
          files: {}
        };
      }
      dataBySessionName[sessionName].files[fileName] = item.Key;
    }

    historyList.current = Object.assign(historyList.current, dataBySessionName);

    const orderOfPreference = [
      "summary-processed.json",
      "summary.json",
      "latest_status.json"
    ];
    // Request updates for all the summary data
    for (const [historyKey, item] of Object.entries(dataBySessionName)) {
      let summaryFile = null;
      if (
        !orderOfPreference.some(p => {
          if (item.files[p]) {
            summaryFile = item.files[p];
            return true;
          }
          return false;
        })
      ) {
        // This one isn't wanted at all, so drop it from the history list
        delete historyList.current[historyKey];

        continue; // No valid file, so skip it.
      }

      let params = {
        Bucket: regionItem.reportsBucket,
        Key: summaryFile
      };
      s3ForRegion.getObject(params, function(err, data) {
        if (err) {
          console.log("Error getting: " + summaryFile);
        } else {
          gotSummary(
            historyKey,
            summaryFile,
            data,
            regionItem,
            s3ForRegion,
            videoFiles
          );
        }
      });
    }
  };

  React.useEffect(() => {
    const doIt = async () => {
      if (username) {
        // Start with an empty list
        historyList.current = {};

        distiAuth.getRegionDataItems().forEach(async regionItem => {
          let s3ForRegion = new AWS.S3({ region: regionItem.id });

          if (regionItem.reportsBucket) {
            let dataContents = [];

            const params = {
              Bucket: regionItem.reportsBucket,
              Prefix: "results/" + username
            };
            while (true) {
              // TODO: Rework to handle .IsTruncated (like instructor\lambda\ContentMangement\lessonReports.js)
              try {
                const response = await s3ForRegion
                  .listObjectsV2(params)
                  .promise();

                dataContents = dataContents.concat(response.Contents);

                if (response.IsTruncated) {
                  params.ContinuationToken = response.NextContinuationToken;
                } else {
                  break;
                }
              } catch (e) {
                console.log(
                  "Problem accessing bucket " + regionItem.reportsBucket,
                  e
                );
                break;
              }
            }
            gotList(dataContents, regionItem, s3ForRegion);
          }
        });
      }
    };
    doIt();
  }, [username]);

  const handleCancel = () => {
    setShowTrainingLogFile("");
  };
  const handleShowVideosCancel = () => {
    setShowVideos(null);
  };
  const errorColumnStyle = {
    style: {
      borderRight: "1px solid black",
      bacgkroundColor: "red"
    }
  };
  const classes = useStyles();
  const largeDialogStyles = {
    dialogPaper: {
      minHeight: "80vh",
      maxHeight: "80vh"
    }
  };
  //		<DialogTitle id="form-dialog-title"><CloseIcon style={{float:"right"}} fontSize="large" onClick={handleCancel} color="primary"/></DialogTitle>
  return (
    <>
      <Dialog
        open={!!showTrainingLogFile}
        onClose={handleCancel}
        fullWidth={true}
        maxWidth={false}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <CloseIcon
            style={{ float: "right" }}
            fontSize="large"
            onClick={handleCancel}
          />
        </DialogTitle>
        <DialogContent>
          <TrainingLog
            username={username}
            logFileInfo={showTrainingLogFile}
            onApply={handleCancel}
          />{" "}
        </DialogContent>
      </Dialog>
      <Dialog
        open={Boolean(showVideos && showVideos.releventVideos)}
        onClose={handleShowVideosCancel}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="form-dialog-title"
      >
        {showVideos ? (
          <>
            <DialogTitle id="videos-dialog">
              <span style={{ float: "left" }}>
                {username}
                <br />
                {showVideos.content} {showVideos.lesson}{" "}
                {showVideos.trainingMode}
              </span>
              <CloseIcon
                style={{ float: "right" }}
                fontSize="large"
                onClick={handleShowVideosCancel}
              />
            </DialogTitle>
            <DialogContent>
              <VideoBrowser videos={showVideos} />
            </DialogContent>
          </>
        ) : null}
      </Dialog>

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <HistoryIcon />
              </CardIcon>
              <h1 className={classes.cardIconTitle}>
                Student History
                <span style={{ float: "right" }}>{username}</span>
              </h1>
            </CardHeader>

            <CardBody>
              <ReactTable
                data={Object.values(historyList.current)}
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  if (row[id] !== undefined) {
                    const haystack = String(row[id]).toLowerCase();
                    const needle = String(filter.value).toLowerCase();
                    return haystack.includes(needle);
                  }
                  return true;
                }}
                /*
                    entry.countryCode = item.countryCode;
                    entry.lesson = item.currentLesson;
                    entry.content = item.currentContent;
                    entry.percentComplete = item.percentComplete;
                  
*/
                columns={[
                  {
                    Header: "Region",
                    columns: [
                      {
                        accessor: "region"
                      }
                    ]
                  },
                  {
                    Header: "Country",
                    columns: [
                      {
                        accessor: "countryCode"
                      }
                    ]
                  },
                  {
                    Header: "Date/Time",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "dateTime"
                      }
                    ]
                  },
                  {
                    Header: "Content",
                    columns: [
                      {
                        accessor: "content"
                      }
                    ]
                  },
                  {
                    Header: "Lesson",
                    columns: [
                      {
                        accessor: "lesson"
                      }
                    ]
                  },
                  {
                    Header: "Mode",
                    columns: [
                      {
                        accessor: "trainingMode"
                      }
                    ]
                  },
                  {
                    Header: "Errors",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "totalErrors"
                      }

                      /*
   					    {
						  Header: "Added",
						  accessor: "AddedActions",
						},
						{
						  Header: "Incorrect",
						  accessor: "IncorrectActions"
						},
						{
						  Header: "Missed",
						  accessor: "MissedActions"
						  //,getProps: () => errorColumnStyle
						},
						{
						  Header: "Order",
						  accessor: "OutOfOrderActions"
						  //,getProps: () => errorColumnStyle
						},
						{
							Header: "Total",
							accessor: "totalErrors"
						}
                        */
                    ]
                  },
                  {
                    Header: "Hints",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "hints"
                      }
                    ]
                  },
                  {
                    Header: "Score",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "score"
                      }
                    ]
                  },
                  {
                    Header: "Passed",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "passed"
                      }
                    ]
                  },
                  {
                    Header: "Detail",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "viewLog",
                        sortable: false
                      }
                    ]
                  },
                  {
                    Header: "Video",
                    headerStyle: {
                      textAlign: "center",
                      borderRightStyle: "inset"
                    },
                    columns: [
                      {
                        accessor: "viewVideos",
                        sortable: false
                      }
                    ]
                  }
                ]}
                defaultSorted={[
                  {
                    id: "dateTime",
                    desc: true
                  }
                ]}
                pageSize={Object.keys(historyList.current).length}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
