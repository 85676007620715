import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import distiAuth from "disti-auth.js";
import settings from '../../aws-exports.json';
import RenameStudentsDialog from './RenameStudentsDialog.js';

const Processing = ({ processingData, onClose, progressMessage }) => {

    const handleCancel = () => {
        onClose();
    }

    return (
        <>
            <Dialog
                open={true}
                fullWidth={false}
                maxWidth={'sm'}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {
                        (!processingData) ? "Starting..." :
                            `Updating ${processingData.totalEntries}`
                    }
                    <CloseIcon
                        style={{ position: "absolute", right: "25px", zIndex: "1" }}
                        fontSize="large"
                        onClick={handleCancel}
                    />
                </DialogTitle>
                <DialogContent>
                    <div style={{ textAlign: "center" }}>{progressMessage}</div><br />
                    <Button
                        color="primary"
                        style={{
                            textAlign: "center",
                            marginLeft: "100px",
                            marginRight: "100px",
                            marginTop: "20px",
                            marginBottom: "20px"
                        }}
                        onClick={handleCancel}>
                        {"Close"}
                    </Button>

                </DialogContent>
            </Dialog>


        </>)

}
export default function RenameStudentEmail({ setShowRenameIdManagement, anonymize }) {
    const [renameSystemActive, renameStatus, performRename] = distiAuth.useRenameSystem(anonymize);

    const doIt = async (entry) => {
        let emails = [];
        for (const item of entry) {
            emails.push({ originalEmail: item.email, targetEmail: item.targetEmail });
        }
        console.log("emails: ", emails);
        try {
            // setDoingRename(true);
            await performRename(emails);
            console.log(renameStatus);
        }
        catch (e) {
            window.alert("Error occurred");
        }
    }

    return (renameSystemActive || renameStatus) ?
        <Processing processingData={renameStatus} onClose={() => setShowRenameIdManagement(false)} 
        progressMessage={renameStatus ? ((renameStatus.doneCount < renameStatus.startCount) ? `Processed: ${((renameStatus.doneCount / renameStatus.startCount) * 100.0).toFixed(2)}%`: "Done") : ""} /> :
        (<Dialog
            open={true}
            onClose={() => {
                setShowRenameIdManagement(false);
            }}
            fullWidth={true}
            maxWidth={false}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <CloseIcon
                    style={{ float: "right" }}
                    fontSize="large"
                    onClick={() => setShowRenameIdManagement(false)}
                />
                <DialogContent>
                    <RenameStudentsDialog
                        onProcessData={doIt}
                        onCancel={() => setShowRenameIdManagement(false)}
                        anonymize={anonymize}
                    />
                </DialogContent>
            </DialogTitle>
        </Dialog>)
}