import React from "react";
import ReactTable from "react-table-6";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import distiAuth from "disti-auth";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LessonSettingsPage from "views/Pages/LessonSettingsPage.js";
import ListIcon from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/core/styles";
import ManageLessonMods from "views/Pages/ManageLessonMods.js";
import SettingsIcon from "@material-ui/icons/Settings";

const styles = {
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

export default function LessonsPage() {
    const userData = distiAuth.getUserData();

    const [showLessonPassingScores, setShowLessonPassingScores] = React.useState(false);
    const [showManageLessonMods, setShowManageLessonMods] = React.useState(false);
    const { allowedActions, assignedMarkets } = React.useContext(
        distiAuth.AllowedActionsContext
      );


      console.log(allowedActions);

    const classes = useStyles();
    return (
        <>
            <Dialog
                open={showLessonPassingScores}
                onClose={() => {
                    setShowLessonPassingScores(false);
                }}
                fullWidth={true}
                maxWidth={false}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <CloseIcon
                        style={{ float: "right" }}
                        fontSize="large"
                        onClick={() => setShowLessonPassingScores(false)}
                    />
                </DialogTitle>
                <DialogContent>
                    <LessonSettingsPage />
                </DialogContent>
            </Dialog>
            <Dialog
                open={showManageLessonMods}
                onClose={() => {
                    setShowManageLessonMods(false);
                }}
                fullWidth={true}
                maxWidth={false}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <CloseIcon
                        style={{ float: "right" }}
                        fontSize="large"
                        onClick={() => setShowManageLessonMods(false)}
                    />
                </DialogTitle>
                <DialogContent>
                    <ManageLessonMods />
                </DialogContent>
            </Dialog>
            <GridContainer xs={12}>
                <GridItem xs={6}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                            <SettingsIcon />
                            </CardIcon>
                            <h1 className={classes.cardIconTitle}>Lessons</h1>
                        </CardHeader>
                        <CardBody></CardBody>
                    </Card>
                    <Card>
                        <CardHeader color="primary" icon>
                            <h2 className={classes.cardIconTitle}>Lesson Passing Scores</h2>
                        </CardHeader>
                        <CardBody>
                            <Button
                            style={
                                {
                                float: "right"
                                } /*position:"absolute", right:"20px", bottom:"5px"*/
                            }
                            round
                            color="primary"
                            disabled={false}
                            onClick={() => {
                                setShowLessonPassingScores(true);
                            }}
                            >
                            Set Lesson Passing Scores <ListIcon />
                            </Button>
                        </CardBody>
                    </Card>
                    {allowedActions.assignLessonModToMarket ? (
                        <Card>
                            <CardHeader color="primary" icon>
                                <h2 className={classes.cardIconTitle}>Lesson Modifications</h2>
                            </CardHeader>
                            <CardBody>
                                <Button
                                style={
                                    {
                                    float: "right"
                                    } /*position:"absolute", right:"20px", bottom:"5px"*/
                                }
                                round
                                color="primary"
                                disabled={false}
                                onClick={() => {
                                    setShowManageLessonMods(true);
                                }}
                                >
                                Manage Lesson Mods <ListIcon />
                                </Button>
                            </CardBody>
                        </Card>
                    ) : null }
                </GridItem>
            </GridContainer>
        </>
    )
}