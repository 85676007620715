import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage({ onApply, email, nonce }) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [resetPassword, setResetPassword] = React.useState(false);

  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const minPasswordLen = 5;
  const newPasswordsGood = (p1, p2) => {
    return p1 === p2 && p1.length >= minPasswordLen;
  };

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Password Change</h4>
              </CardHeader>
              <CardBody>
                <div style={{ textAlign: "center" }}>
                  <b>{email}</b>
                </div>
                <CustomInput
                  labelText="New Password"
                  id="password1"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: e => {
                      setPassword1(e.target.value);
                    }
                  }}
                />
                <CustomInput
                  labelText="Retype New Password"
                  id="password2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: e => {
                      setPassword2(e.target.value);
                    }
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  style={{
                    opacity: !newPasswordsGood(password1, password2) ? 0.25 : 1,
                    pointerEvents: !newPasswordsGood(password1, password2)
                      ? "none"
                      : "initial"
                  }}
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => {
                    onApply(email, password1);
                  }}
                >
                  {password1 !== password2
                    ? "Passwords Don't Match"
                    : password1.length < minPasswordLen
                    ? "Password Too Short"
                    : "Apply"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
