import React from "react";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CardHeader from "components/Card/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorIcon from "@material-ui/icons/Error";
import { Grid } from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import ReactTable from "react-table-6";
import settings from '../../aws-exports.json';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import distiAuth from "disti-auth.js";
// import dataSource from "DataSource.js";
import { TextField } from "@material-ui/core";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js";
import util from "util.js"
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js";


const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    entryLine: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px"
    }
});

export default function renameStudentsDialog({
    onProcessData,
    onCancel,
    anonymize
}) {
    const { allowedActions, assignedMarkets } = React.useContext(
        distiAuth.AllowedActionsContext
    );
    const [showDialog, setShowDialog] = React.useState(false);
    const [tempRawData, setTempRawData] = React.useState("");
    const [rawData, setRawData] = React.useState("");
    const [bulkEditData, setBulkEditData] = React.useState({
        items: [],
        status: { errors: false, warningsCount: 0 }
    });
    let {
        loading: queryLoading,
        data: queryData
    } = SubscriptionHelpers.useSubscribedStudentsQuery();// dataSource.useDataQuery("listStudents");
    const [checkingData, setCheckingData] = React.useState(false);
    const [warningsAcknowledged, setWarningsAcknowledged] = React.useState(
        new Set()
    );
    const addRemoveWarningsAcknowledged = (add, email) => {
        let copy = new Set(warningsAcknowledged);
        if (add) {
            copy.add(email);
        } else {
            copy.delete(email);
        }
        setWarningsAcknowledged(copy);
    };
    const classes = useStyles();

    React.useEffect(() => {
        setCheckingData(true);
        const doIt = () => {
            if (anonymize) {
                if (!queryLoading && queryData && queryData.listStudents && rawData != "") {
                    let allStudentsRaw = queryData.listStudents.items;
                    let calculated = [];
                    let status = {
                        errors: false, warningsCount: 0
                    };

                    let emails = rawData.replace(/\r?\n/, " ").split(/[ ,]+/);
                    console.log(emails);

                    let rowNum = 0;
                    const allStudentsLower = allStudentsRaw.map(item => item.id.toLowerCase());
                    for (const rawEmail of emails) {

                        const email = rawEmail.toLowerCase();

                        const errorEntries = [];
                        const warningEntries = [];

                        rowNum++;
                        const existingEntryById = allStudentsLower.find(item => item === email);
                        if (!existingEntryById) {
                            status.errors = true;
                            errorEntries.push((
                                <>
                                    Email is not in the system.
                                </>), (<br />));
                        }

                        if (!allowedActions.noMarketRestrictions) {
                            const assignedMarketsSet = new Set(assignedMarkets);
                            if (!assignedMarketsSet.has(existingEntryById.market)) {
                                status.errors = true;
                                errorEntries.push((
                                    <>
                                        {`Insufficient permissions to access this market.`}
                                    </>),
                                    (<br />)
                                );
                            }
                        }

                        const alreadyListed = calculated.find(item3 => item3.email === email);
                        if (alreadyListed) {
                            status.errors = true;
                            errorEntries.push((
                                <>
                                    Original Email already included.
                                </>), (<br />)
                            )
                        }

                        let newEntry = {
                            email: email,
                            targetEmail: "Anonymized Student",
                            errors: errorEntries,
                            warnings: warningEntries,
                            errorWarningSortData: (errorEntries.length ? -2e12 : 0) +
                                (warningEntries.length ? -2e8 : 0) +
                                rowNum
                        }

                        calculated.push(newEntry);
                    }

                    console.log(calculated);

                    setBulkEditData({
                        items: calculated,
                        status: status
                    });
                }
                setCheckingData(false);
            } else {
                if (!queryLoading && queryData && queryData.listStudents) {
                    let allStudentsRaw = queryData.listStudents.items;
                    let calculated = [];
                    let status = {
                        errors: false, warningsCount: 0
                    };

                    let inputPairs = rawData.split(/\r?\n/);
                    console.log(inputPairs);

                    let rowNum = 0;
                    const allStudentsLower = allStudentsRaw.map(item => item.id.toLowerCase());
                    for (const p of inputPairs) {
                        const errorEntries = [];
                        const warningEntries = [];
                        const pair = p.trim(); // Ignoring leading or trailing whitespace
                        if (!pair) continue; // Skip blank lines

                        // Split on whitespace
                        // Note that this will collapse multiple whitespace

                        rowNum++;
                        let [email, targetEmail] = pair.split(/\s+/); // /[ ]+/);
                        // All emails are treated as lowercase.
                        email = email.toLowerCase();
                        targetEmail = targetEmail.toLowerCase();

                        const existingEntryById = allStudentsLower.find(item => item === email);
                        if (!existingEntryById) {
                            status.errors = true;
                            errorEntries.push((
                                <>
                                    Email is not in the system.
                                </>), (<br />));
                        }

                        if (!targetEmail) {
                            status.errors = true;
                            errorEntries.push(
                                <>
                                    {'Target email must be supplied.'}
                                </>,
                                (<br />)
                            )
                        } else if (!util.checkEmailFormatGood(targetEmail)) {
                            status.errors = true;
                            errorEntries.push(
                                <>
                                    {'Target email must be valid.'}
                                </>,
                                (<br />)
                            )
                        }

                        const existingTargetId = allStudentsLower.find(item2 => item2 === targetEmail);
                        if (existingTargetId) {
                            status.errors = true;
                            errorEntries.push((
                                <>
                                    Target email is in use.
                                </>), (<br />)
                            )
                        }

                        let alreadyListed = calculated.find(item3 => item3.email === email);
                        if (alreadyListed) {
                            status.errors = true;
                            errorEntries.push((
                                <>
                                    Original Email already included.
                                </>), (<br />)
                            )
                        }

                        alreadyListed = calculated.find(item4 => item4.targetEmail === targetEmail);
                        if (alreadyListed) {
                            status.errors = true;
                            errorEntries.push((
                                <>
                                    Target Email already included.
                                </>), (<br />)
                            )
                        }

                        if (!allowedActions.noMarketRestrictions) {
                            const assignedMarketsSet = new Set(assignedMarkets);
                            if (!assignedMarketsSet.has(existingEntryById.market)) {
                                status.errors = true;
                                errorEntries.push((
                                    <>
                                        {`Insufficient permissions to access this market.`}
                                    </>),
                                    (<br />)
                                );
                            }
                        }

                        let newEntry = {
                            email: email,
                            targetEmail: targetEmail,
                            errors: errorEntries,
                            warnings: warningEntries,
                            errorWarningSortData: (errorEntries.length ? -2e12 : 0) +
                                (warningEntries.length ? -2e8 : 0) +
                                rowNum
                        }

                        calculated.push(newEntry);
                    }

                    setBulkEditData({
                        items: calculated,
                        status: status
                    });
                }
                setCheckingData(false);
            }
        }

        doIt();
    }, [rawData, queryLoading, queryData?.listStudents?.items?.length]);


    const handleApply = async () => {
        const isConfirmed = window.confirm(
            "Are you sure you want to permanently change " + bulkEditData.items.length + " email(s)? These students will no longer be able to log into the system with previous email. Are you sure you want to continue?"
        );
        if (isConfirmed) {
            onProcessData([...bulkEditData.items]);
        }
    }

    const handleCancel = () => {
        onCancel();
        console.log("evoke cancel");
    };

    const okayToEditAll = () => {
        return (
            bulkEditData &&
            !bulkEditData.status.errors &&
            bulkEditData.status.warningsCount <= warningsAcknowledged.size
        );
    };

    return (
        <>
            <Dialog
                open={Boolean(showDialog)}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="form-dialog-title"
            >
                <>
                    <DialogTitle id="form-dialog-title">
                        <CloseIcon
                            style={{ position: "absolute", right: "25px", zIndex: "1" }}
                            fontSize="large"
                            onClick={handleCancel}
                        />
                    </DialogTitle>
                    <DialogContent>
                        {
                            anonymize ?
                                (<>Input email addresses separated by white space or new line.
                                    <br />
                                    ex:
                                    <br />
                                    <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;example1@example.com&nbsp;&nbsp;&nbsp;example2@example.com</i>
                                    <br />
                                    <br /></>) :
                                (<>Input an email address followed by a new email address. Separate each entry with a new line.
                                    <br />
                                    ex:
                                    <br />
                                    <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;example1@example.com&nbsp;&nbsp;&nbsp;example2@example.com</i>
                                    <br />
                                    <br />
                                </>)
                        }
                        <TextField
                            fullWidth
                            error={!tempRawData}
                            id="standard-multiline-flexible"
                            label="List of Email Addresses"
                            multiline
                            value={tempRawData}
                            minRows={10}
                            maxRows={20}
                            onChange={e => { setTempRawData(e.target.value) }}
                            variant="outlined"
                        />
                        <br />
                        <br />
                        <Button
                            color="primary"
                            disabled={!tempRawData}
                            style={{
                                float: "left",
                                marginLeft: "100px",
                                marginTop: "20px",
                                marginBottom: "20px"
                            }}
                            onClick={() => {
                                setRawData(tempRawData)
                                setShowDialog(false);
                            }}>
                            Validate
                        </Button>
                        <Button
                            color="primary"
                            style={{
                                float: "right",
                                marginLeft: "100px",
                                marginTop: "20px",
                                marginBottom: "20px"
                            }}
                            onClick={() => {
                                handleCancel();
                            }}>
                            Cancel
                        </Button>
                    </DialogContent>
                </>
            </Dialog>
            <Grid container>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <PersonIcon />
                            </CardIcon>
                            <h1 className={classes.cardIconTitle}>{
                                anonymize ?
                                    (<>Anonymize Student Email Addresses</>) :
                                    (<>Change Student Email Addresses</>)
                            }</h1>
                            <span style={{ float: "right" }}>
                                <Button
                                    round
                                    color="primary"
                                    disabled={false}
                                    onClick={() => {
                                        setTempRawData(rawData);
                                        setShowDialog(true);
                                    }}>
                                    Input Email Addresses
                                </Button>
                            </span>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={bulkEditData.items}
                                style={{
                                    height: "calc( 100vh - 450px)"
                                }}
                                columns={[
                                    {
                                        Header: "",
                                        accessor: "errorWarningSortData",
                                        width: 100,
                                        Cell: row => [
                                            row.original.errors.length ? (
                                                <Tooltip
                                                    key={"error_" + row.original.row}
                                                    title={row.original.errors}
                                                >
                                                    <ErrorIcon
                                                        style={{ float: "left", color: "red" }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                ""
                                            ),
                                            row.original.warnings.length ? (
                                                <span
                                                    key={"warning_" + row.original.row}
                                                    style={{ float: "right" }}
                                                >
                                                    <Tooltip title={row.original.warnings}>
                                                        <WarningIcon
                                                            style={{ color: "orange" }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={"Acknowledge Warning"}
                                                        style={{ float: "left" }}
                                                    >
                                                        <Checkbox
                                                            style={{ float: "right", padding: "0px" }}
                                                            color="primary"
                                                            checked={warningsAcknowledged.has(
                                                                row.original.email
                                                            )}
                                                            onChange={e => {
                                                                addRemoveWarningsAcknowledged(
                                                                    e.target.checked,
                                                                    row.original.email
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            ) : (
                                                ""
                                            ),
                                            row.original.marketError ? (
                                                <Tooltip
                                                    key={"market_error_" + row.original.row}
                                                    title={row.original.marketError}
                                                >
                                                    <ErrorIcon
                                                        style={{ float: "left", color: "red" }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                ""
                                            )
                                        ]
                                    },
                                    {
                                        Header: "Original Email",
                                        accessor: "email",
                                        getProps: (state, rowInfo, column) => {
                                            if (!rowInfo) {
                                                return {};
                                            }
                                            return {
                                                style: rowInfo.original.errors.length
                                                    ? { textShadow: "0px 0px 20px red" }
                                                    : {}
                                            };
                                        },
                                        Cell: row => <Typography>{row.value}</Typography>
                                    },
                                    {
                                        Header: "Target Email",
                                        accessor: "targetEmail",
                                        Cell: row => <Typography>{row.value}</Typography>
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "errorWarningSortData",
                                        desc: false
                                    }
                                ]}
                            />
                            {checkingData ? (
                                <CircularProgress
                                    disableShrink
                                    style={{
                                        position: "absolute",
                                        top: "250px",
                                        // Center it left/right:
                                        left: "0px",
                                        right: "0px",
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    }}
                                />
                            ) : null}
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <Button
                color="primary"
                style={{
                    float: "left",
                    marginLeft: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                disabled={!okayToEditAll()}
                onClick={async () => {
                    handleApply();
                }}
            >
                Apply
            </Button>
            <Button
                style={{
                    float: "right",
                    marginRight: "100px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
                onClick={() => {
                    handleCancel();
                }}
            >
                Cancel
            </Button>
        </>
    )
}