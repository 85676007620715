import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// react component for creating dynamic tables
import ReactTable from "react-table-6";

import gql from "graphql-tag";
import { useQuery, useMutation, useSubscription } from "@apollo/client";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import StudentEdit from "components/StudentTable/StudentEdit.js";
import distiAuth from "disti-auth.js";
import util from "util.js";
import settings from '../../aws-exports.json';

import Queries from "GraphQL/InstructorAccess.js";
import SubscriptionHelpers from "GraphQL/SubscriptionHelpers.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const defaultFilterMethod = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== undefined) {
    const haystack = String(row[id]).toLowerCase();
    const needle = String(filter.value).toLowerCase();
    return haystack.includes(needle);
  }
  return true;
}
const pageSizeOptions = [10, 20, 50, 100, 200]
const defaultSorted = [
  {
    id: "inClass",
    desc: true
  },
  {
    id: "fullName",
    desc: false
  }
]
const tableStyle = {
  height: "calc( 100vh - 290px)"
}
export default function StudentSelector2({ initialList, onApply, onClose }) {
  const { allowedActions, assignedMarkets } = React.useContext(
    distiAuth.AllowedActionsContext
  );

  let [tempStudentList, setTempStudentList] = React.useState(initialList);

  const [showCreateStudent, setShowCreateStudent] = React.useState(false);

  const columns = React.useMemo(()=>[
    {
      Header: "In Class",
      accessor: "inClass",
      width: 150,
      Cell: props => (
        <Checkbox
          color="primary"
          checked={props.value}
          onChange={e => {
            addRemoveToStudentList(
              e.target.checked,
              props.row.email
            );
          }}
        />
      )
    },
    {
      Header: "Name",
      accessor: "fullName"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Market",
      accessor: "marketName"
    },
    {
      Header: "Dealer",
      accessor: "dealer"
    },
    {
      Header: settings.personIdFieldName,
      accessor: "personId"
    }
  ],[addRemoveToStudentList]);

  async function doCreate() {
    setShowCreateStudent(true);
  }

  const handleApply = () => {
    onApply(tempStudentList);
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };

  //const [data, setData] = React.useState(calcRows([]));

  let {
    loading: queryLoading,
    data: queryData
  } = SubscriptionHelpers.useSubscribedStudentsQuery(); //useQuery(Queries.STUDENTS.ALL);

  const [
    createStudent,
    { loading: createStudentLoading, error: createStudentError }
  ] = useMutation(Queries.STUDENTS.CREATE);

  const { data: marketQueryData } = useQuery(Queries.MARKETS.ALL);
  let marketDataById = {};
  if (marketQueryData && marketQueryData.listMarkets.items) {
    marketQueryData.listMarkets.items.forEach(item => {
      marketDataById[item.id] = item;
    });
  }

  if (createStudentError) {
    console.log("Problem creating student: " + createStudentError);
  }
  // if (errorSub)
  // {
  // console.log("Got errorSub: "+JSON.stringify(errorSub));
  // }

  let deleteFromArray = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
  };

  const studentList = React.useMemo(()=>{
    let studentList = []
    if (queryData) {
      let deletedStudents = tempStudentList ? [...tempStudentList] : [];

      const assignedMarketsSet = new Set(assignedMarkets);
      studentList = [...queryData.listStudents.items]
        .filter(item => {
          return (
            allowedActions.noMarketRestrictions ||
            assignedMarketsSet.has(item.market)
          );
        })
        .map(item => {
          if (tempStudentList && tempStudentList.includes(item.id)) {
            deleteFromArray(deletedStudents, item.id);
          }
          return {
            key: item.id,
            id: item.id,
            inClass: tempStudentList && tempStudentList.includes(item.id),
            email: item.id,
            fullName: item.fullName,
            market: item.market,
            marketName:
              item.market &&
              Object.hasOwnProperty.call(marketDataById, item.market)
                ? marketDataById[item.market].name
                : item.market, // We are transitioning from the name being the value to a GUID... assuming its the name if we can't find the GUID, for now.
            dealer: item.dealer,
            personId: item.personId
          };
        });
      for (let s in deletedStudents) {
        let item = deletedStudents[s];
        studentList.push({
          key: item.id,
          id: item.id,
          inClass: true,
          email: item.id,
          fullName: <i>DELETED or MOVED MARKET</i>,
          market: item.market,
          marketName:
            item.market &&
            Object.prototype.hasOwnProperty.call(marketDataById, item.market)
              ? marketDataById[item.market].name
              : item.market, // We are transitioning from the name being the value to a GUID... assuming its the name if we can't find the GUID, for now.
          dealer: item.dealer,
          personId: item.personId
        });
      }
    }
    
    return studentList
    
  },[tempStudentList.join(','),assignedMarkets.join(','),queryData?queryData.listStudents.items.length:0])

    /*
	   //console.log("Got data: "+JSON.stringify(data));  item.id
       var studentList = queryData.listStudents.items.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.fullName}</td>
			<th><i className="material-icons" style={{cursor: "pointer"}} onClick={ ()=>{doDelete(item.id);} }>delete</i></th>
            </tr>
          ));
		  */
  
  const addRemoveToStudentList = React.useCallback((add, studentId) => {
    setTempStudentList((was)=>{
      const copy = new Set(was);
      if (add) {
        copy.add(studentId);
      } else {
        copy.delete(studentId);
      }
      return Array.from(copy)
    });
  },[setTempStudentList]);

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={false}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Select Students for class
        {allowedActions.createStudents ? (
          <Button
            round
            color="primary"
            onClick={() => {
              doCreate();
            }}
            style={{ float: "right" }}
          >
            <PersonAdd />
            Add New Student
          </Button>
        ) : (
          ""
        )}
      </DialogTitle>
      <DialogContent>
        {showCreateStudent ? (
          <StudentEdit
            createNew={true}
            initialValue={{}}
            onValidateNewEmail={candidateEmail => {
              const lowerCandidate = (candidateEmail || "").toLowerCase();
              // Return valid:true if candidate is available to be used
              // If a candidate should not be used, include the reason.

              // if a student entry does not have a market attached, it is treated as available for creation.
              // Note that no-market students are vestigual.  New students all require a market.
              const foundWithMarket = queryData.listStudents.items.some(
                item => {
                  return item.id.toLowerCase() == lowerCandidate && item.market;
                }
              );

              const foundInControlledMarket = studentList.some(item => {
                return item.id.toLowerCase() == lowerCandidate;
              });

              return {
                valid: !foundWithMarket,
                reason: foundInControlledMarket
                  ? "Email already exists in the system"
                  : "Email already exists in another market in the system"
              };
            }}
            onApply={update => {
              createStudent({
                variables: {
                  pk: update.email,
                  fullName: update.fullName,
                  market: update.market,
                  dealer: update.dealer,
                  personId: update.personId,
                }
              });
              addRemoveToStudentList(true, update.email);
            }}
            lookupStudentsForPersonId={candidatePersonId => {
              return util.getStudentsByPersonId(queryData.listStudents.items, candidatePersonId)
            }}
            onClose={() => setShowCreateStudent(false)}
          />
        ) : (
          ""
        )}

        <GridContainer>
          <GridItem xs={12}>
            <Card style={{margin:0}}>
              <CardBody>
                <ReactTable
                  style={tableStyle}  
                  data={studentList}
                  filterable
                  defaultFilterMethod={defaultFilterMethod}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  //pageSize={studentList.length}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  pageSizeOptions ={ pageSizeOptions}
                  defaultPageSize ={50}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
