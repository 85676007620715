import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from "@material-ui/icons/Update";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import EmailIcon from "@material-ui/icons/Email";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import InputAdornment from "@material-ui/core/InputAdornment";
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";
import ListIcon from "@material-ui/icons/List";
import { Grid } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import pLimit from 'p-limit';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ReactTable from "react-table-6";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js";

import distiAuth from "disti-auth.js";
import util from "util.js";
import Snackbar from 'components/Snackbar/Snackbar.js';
import settings from '../../aws-exports.json';
import SubscriptionHelpers from "../../GraphQL/SubscriptionHelpers.js";
//"../GraphQL/SubscriptionHelpers.js";
import BulkPersonIdEdit from './BulkPersonIdEdit.js';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    entryLine: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px"
    }
});

//const status = {startCount: 0, doneCount: 0, cancel: false}

const Processing = ({processingData, onClose})=>
{
    const [
        updateStudent,
    ] = useMutation(Queries.STUDENTS.UPDATE);

    const [progressMessage, setProgressMessage] = React.useState("");
    const statusRef = React.useRef({startCount: 0, doneCount: 0, cancel: false, done: false})
    const status = statusRef.current;

    const limit = pLimit(20);

    const handleCancel = ()=>
    {
        limit.clearQueue(); 
        status.cancel = true;
        onClose();
    }

    React.useEffect(() => {
        if (!processingData || status.done) return;
        const doIt = async ()=>{
            const isConfirmed = window.confirm(
                "Are you sure you want to modify " + processingData.length + " items?"
            );
            if (isConfirmed) {
                status.cancel = false;
                const sendUpdate = async (entry)=>
                {
                    await updateStudent({
                        variables: {
                            pk: entry.email,
                            personId: entry.personId,
                        }
                    });
                    status.doneCount++;
                }

                let allUpdates = []            
                                    
                for (const entry of processingData) {
                    if (status.cancel) return;
                    allUpdates.push(limit(sendUpdate, entry))
                }
                status.startCount = allUpdates.length;

                const trackStatus = async ()=>{
                    while (status.startCount > status.doneCount)
                    {
                        if (status.cancel)
                        {
                            setProgressMessage(`Canceled`)
                            return
                        }
                        await sleep(1000);
                        const percentString = ((status.doneCount / status.startCount) * 100.0).toFixed(2)
                        setProgressMessage(`Processed: ${percentString}%`)
                    }
                    setProgressMessage(`Processing complete`)
                }
                await Promise.all([Promise.all(allUpdates),trackStatus()]);
                status.done = true
                setProgressMessage(`Done`)
                
            }
        }
        doIt();
        return handleCancel
    },[processingData])


    return (
        <>
        <Dialog
        open={true}
        onClose={handleCancel}
        fullWidth={false}
        maxWidth={'sm'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
            Updating {processingData.length} {settings.personIdFieldName}
          <CloseIcon
            style={{ position: "absolute", right: "25px", zIndex: "1" }}
            fontSize="large"
            onClick={handleCancel}
          />
        </DialogTitle>
        <DialogContent>
        <div style={{textAlign:"center"}}>{progressMessage}</div><br/>
        <Button
            color="primary"
            style={{
                textAlign: "center",
                marginLeft: "100px",
                marginRight: "100px",
                marginTop: "20px",
                marginBottom: "20px"
            }}
            onClick={handleCancel}>
            {status.done?"Close":"Cancel"}
        </Button>
                
        </DialogContent>
      </Dialog>


  </>)

}
export default function BulkPersonId({ setShowPersonIdManagement }) {
    const [processingData, setOnProcessingData] = React.useState(null)

    return processingData?
        <Processing processingData={processingData} onClose={()=>setShowPersonIdManagement(false)}/>:
        <BulkPersonIdEdit 
            onProcessData={setOnProcessingData}
            onCancel={()=>setShowPersonIdManagement(false)}
        />
}