import React from "react";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import Queries from "GraphQL/InstructorAccess.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateIcon from "@material-ui/icons/Update";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import InputAdornment from "@material-ui/core/InputAdornment";
import OrganizationChooser from "components/StudentTable/OrganizationChooser.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import MapIcon from "@material-ui/icons/Map";
import LanguageIcon from "@material-ui/icons/Language";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import util from "util.js";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  entryLine: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px"
  }
});

export default ({ open, createNew, onApply, onClose, initialValue }) => {
  if (!open) {
    return "";
  }
  const classes = useStyles();
  // Looks up the name of content by ID.
  let contentNameById = {};

  const [content, setContent] = React.useState(initialValue.content || []);

  const [contentName, setContentName] = React.useState(
    initialValue.contentName || ""
  );
  const [lessonName, setLessonName] = React.useState(
    initialValue.lessonName || ""
  );
  const [criteria, setCriteria] = React.useState(initialValue.criteria || "");

  // Blank is valid, which will mean default
  // Must be a number between 0 and 100, inclusive
  const criteriaValid =
    criteria === "" ||
    (!isNaN(Number(criteria)) &&
      Number(criteria) >= 0 &&
      Number(criteria) <= 100);

  const handleCancel = () => {
    onClose();
  };
  const handleApply = () => {
    onApply({
      ...initialValue,
      criteria
      // TODO
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <CloseIcon
          style={{ position: "absolute", right: "25px", zIndex: "1" }}
          fontSize="large"
          onClick={handleCancel}
        />
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Card className={classes.root}>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <EditIcon />
                </CardIcon>
                <h1 className={classes.cardIconTitle}>{"Edit Content"}</h1>
              </CardHeader>

              <CardContent>
                <Typography color="textSecondary" variant="h5" component="h2">
                  <TextField
                    className={classes.entryLine}
                    required
                    value={contentName}
                    id="name"
                    label="Content Name"
                    disabled={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <DriveEtaIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    className={classes.entryLine}
                    required
                    value={lessonName}
                    id="lessonName"
                    label="Lesson Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AssignmentIcon />
                        </InputAdornment>
                      )
                    }}
                    disabled={true}
                  />
                  <TextField
                    className={classes.entryLine}
                    value={criteria}
                    id="lessonName"
                    label="Passing Score % (blank for default)"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CheckBoxIcon />
                        </InputAdornment>
                      ),
                      error: !criteriaValid
                    }}
                    onChange={e => {
                      setCriteria(e.target.value);
                    }}
                  />
                </Typography>
                <br />
              </CardContent>

              <CardActions></CardActions>
            </Card>
          </GridItem>
        </GridContainer>
        <Button
          color="primary"
          style={{
            float: "left",
            marginLeft: "100px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          onClick={handleApply}
          disabled={!criteriaValid}
        >
          Confirm
        </Button>
        <Button
          style={{
            float: "right",
            marginRight: "100px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
