import AWS from "aws-sdk";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import querystring from "querystring";
import React from "react";
import settings from "../src/aws-exports.json";

window.virtualClassroomUserData = window.virtualClassroomUserData || {};

let runningFromRegion = window.location.host.split(".")[0];
if (runningFromRegion.startsWith("localhost")) {
  runningFromRegion = "us-east-1";
}

const allowedActionsForRoles = roles => {
  return roles.reduce((result, role) => {
    try {
      return { ...result, ...settings.roles[role] };
    } catch (e) {
      console.log("Unknown role, skipping: ", role);
      return result;
    }
  }, {});
};

let distiAuthSettings = {
  userType: "STUDENT",
  baseURL: `https://instructor.${settings.Tenant}.disti.training/`,
  identityPoolId: settings.IdentityPoolId,
  identityPoolRegion: settings.CoreRegion
};
// Returns data which will have been retrieved as part of authorization
const getUserData = () => {
  return window.virtualClassroomUserData;
};

const AllowedActionsContext = React.createContext({});

// "INSTRUCTOR or "STUDENT" are acceptable types
const init = settings => {
  distiAuthSettings = { ...distiAuthSettings, ...settings };

  AWS.config.region = "us-east-1";
  //AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //	IdentityPoolId: 'us-east-1:269226e1-9026-4b11-8a09-b4dec31d8a3d'
  //});
  AWS.config.credentials = null;
};
// This is used to avoid storing and sending clear-text passwords.
// This is only for safegarding the secrecy of the password for the benifit
//  of the creator of the password since re-use is common.
// Proper password hashing occurs on the server.
// Returning a base64 string
const hashPassword = async clearPassword => {
  const encoder = new TextEncoder();
  const data = encoder.encode(clearPassword);
  const hash = await crypto.subtle.digest("SHA-256", data);

  const binary = String.fromCharCode.apply(null, hash);
  return window.btoa(binary);
};

const usernameStorage = { username: "" };
const storeHashedPW = async (username, pw) => {
  usernameStorage.username = username;

  const hashed = { username: username };

  hashed.hash = Base64.stringify(sha256(username + pw));

  sessionStorage.setItem(
    "virtual-classroom-last-auth" + distiAuthSettings.userType,
    JSON.stringify(hashed)
  );
};
const getHashedPW = () => {
  const val = sessionStorage.getItem(
    "virtual-classroom-last-auth" + distiAuthSettings.userType
  );
  if (!val) {
    return null;
  }
  const rval = JSON.parse(val);
  usernameStorage.username = rval.username;

  return rval;
};
const applyNewPassword = async (u, p, resetNonce, onFailure, onSuccess) => {
  await storeHashedPW(u, p);
  const hashedPW = getHashedPW();

  try {
    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: querystring.stringify({
        username: hashedPW.username,
        hash: hashedPW.hash,
        nonce: resetNonce,
        userType: distiAuthSettings.userType
      }),
      url: distiAuthSettings.baseURL + "api/apply_new_password"
    });

    //console.log("Got result of new password: "+JSON.stringify(result));

    onSuccess();
    return;
  } catch (e) {
    console.log(
      "Got exception from apply_new_password: " + JSON.stringify(e.response)
    );
    onFailure(e);
    return;
  }
};

const requestPasswordReset = (username, onFailure, onSuccess) => {
  console.log("Doing password reset for user: " + username);
  try {
    axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: querystring.stringify({
        username: username,
        userType: distiAuthSettings.userType
      }),
      url: distiAuthSettings.baseURL + "api/request_password_reset"
    });

    onSuccess();
    return;
  } catch (e) {
    console.log("Problem requesting password reset: " + e);
    onFailure(e);
    return;
  }
};
const recordAgreementAcceptance = async (
  acceptedAgreementId,
  onFailure,
  onSuccess
) => {
  const hashedPW = getHashedPW();

  try {
    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: querystring.stringify({
        username: hashedPW.username,
        hash: hashedPW.hash,
        userType: distiAuthSettings.userType,
        acceptedAgreementId: acceptedAgreementId
      }),
      url: distiAuthSettings.baseURL + "api/record_agreement_acceptance"
    });

    //console.log("Got result of recordAgreementAcceptance: "+JSON.stringify(result));

    onSuccess();
    return;
  } catch (e) {
    console.log(
      "Got exception from record_agreement_acceptance: " +
      JSON.stringify(e.response)
    );
    onFailure(e);
    return;
  }
};

const requestInviteEmail = async (
  inviteTarget,
  inviteTargetType,
  onFailure,
  onSuccess
) => {
  const hashedPW = getHashedPW();

  try {
    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: querystring.stringify({
        username: hashedPW.username,
        hash: hashedPW.hash,
        userType: distiAuthSettings.userType,
        inviteTarget: inviteTarget,
        inviteTargetType: inviteTargetType
      }),
      url: distiAuthSettings.baseURL + "api/request_invite_email"
    });

    console.log("Got invite result: " + JSON.stringify(result));
    onSuccess();
    return true;
  } catch (e) {
    console.log(
      "Got exception from apply_new_password: " + JSON.stringify(e.response)
    );
    onFailure(e);
    return false;
  }
};

const clearHashedPW = () => {
  sessionStorage.removeItem(
    "virtual-classroom-last-auth" + distiAuthSettings.userType
  );
};
const getCognitoToken = async () => {
  console.log("getCognitoToken called");

  const hashedPW = getHashedPW();
  if (!hashedPW || !hashedPW.hash) {
    throw "No previous PW";
  }

  let { data: output } = await axios({
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType
    }),
    url: distiAuthSettings.baseURL + "api/get_credentials"
  });
  //			'Authorization': 'Basic c29tZUluc3RydWN0b3JAZ21haWwuY29tOkFCQ0RF'
  //'VirtualClassroomAuth': JSON.stringify(hashedPW)

  if (!output.identityId || !output.token) {
    throw "CognitoToken Problem";
  }
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(
    {
      // either IdentityPoolId or IdentityId is required
      // See the IdentityPoolId param for AWS.CognitoIdentity.getID (linked below)
      // See the IdentityId param for AWS.CognitoIdentity.getCredentialsForIdentity
      // or AWS.CognitoIdentity.getOpenIdToken (linked below)
      IdentityPoolId: distiAuthSettings.identityPoolId,
      IdentityId: output.identityId,
      Logins: {
        "cognito-identity.amazonaws.com": output.token
      },

      // optional, only necessary when application runs in a browser
      // and multiple users are signed in at once, used for caching
      LoginId: "TODO"
    },
    {
      // optionally provide configuration to apply to the underlying service clients
      // if configuration is not provided, then configuration will be pulled from AWS.config

      // region should match the region your identity pool is located in
      region: distiAuthSettings.identityPoolRegion,

      // specify timeout options
      httpOptions: {
        timeout: 5000
      }
    }
  );

  if (output.userData) {
    window.virtualClassroomUserData = output.userData;
  }
};

const getCredentialsUpdatedAsNeeded = async () => {
  // TODO: Figure out how to know when we have to re-get the token
  if (!AWS.config.credentials || AWS.config.credentials.needsRefresh()) {
    try {
      await getCognitoToken();
    } catch (e) {
      console.log("Problem getting Cognito Token:", e);
    }
  }

  try {
    if (AWS.config.credentials) {
      await AWS.config.credentials.getPromise();
    } else {
      console.log("AWS.config.credentials are null");
    }
  } catch (e) {
    console.log(
      "Problem with credentials.getPromise().  Calling refreshPromise():",
      e
    );
    try {
      await AWS.config.credentials.refreshPromise();
    } catch (e) {
      console.log("Problem with credentials.refreshPromise():", e);
      return null;
    }
  }

  return AWS.config.credentials;
};

const getAvailableClasses = async (params, onFailure, onSuccess) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      region: params.region,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType
    });
    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_available_classes"
    });

    //console.log("Got result of get_available_classes: "+JSON.stringify(result));

    if (result.status == "200") {
      // Getting userData as a necessary side effect
      if (result.data && result.data.userData) {
        window.virtualClassroomUserData = result.data.userData;
      }

      if (onSuccess) onSuccess(result.data.data);

      return result.data.data;
    } else {
      if (onFailure) onFailure(result.status);
      return null;
    }
  } catch (e) {
    console.log("Got exception from getAvailableClasses: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};
const getStreamingURL = async (params, onFailure, onSuccess) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      region: params.region, //"us-east-1", //TODO: Get this from a header from cloud front
      classId: params.classId,
      reportGroup: params.reportGroup, //"results",
      hash: hashedPW.hash,
      facilitator: Boolean(params.facilitator),
      userType: distiAuthSettings.userType,
      cc: params.cc
    });
    console.log("Stringified: " + q);
    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_streaming_url"
    });

    //console.log("Got result of get_streaming_url: "+JSON.stringify(result));

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from getStreamingURL: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};

const getDocumentationCredentials = async () => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType
    });
    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_doc_url_with_auth"
    });

    return result.data;
  } catch (e) {
    console.log("Got exception from getDocumentationCredentials: " + e);
    return null;
  }
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// Can take a while, so don't await for it on the main thread
const getAccessHistoryByYearMonthForResponsibleParty = async params => {
  try {
    const hashedPW = getHashedPW();
    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      responsibleParty: "results",
      year: "" + params.year,
      month: "" + params.month,
      userType: distiAuthSettings.userType,
      marketIds: params.marketIds
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url:
        distiAuthSettings.baseURL + "api/get_month_data_for_responsible_party"
    });

    //console.log("Got result of get_month_data_for_responsible_party: "+JSON.stringify(result));

    // Now keep trying to read the returned URL until time expires
    // We are in an async, so not in a hurry to return
    const tryUntil = new Date() / 1000 + 120; /* seconds */
    while (new Date() / 1000 < tryUntil) {
      //result.expiresAt)
      try {
        const s3GetResult = await axios({
          method: "GET",
          headers: { "content-type": "application/json" },
          url: result.data.url
        });

        console.log(
          "Result of S3 presigned get attempt: " + JSON.stringify(s3GetResult)
        );
        if (s3GetResult && s3GetResult.data) {
          return s3GetResult.data; //{data: s3GetResult.data, url: result.data.url};
        }
      } catch (e) {
        console.log("got exception: " + e);
      }
      await sleep(3000);
    }
  } catch (e) {
    console.log("Got exception from get_month_data_in_region: " + e);
    throw "Got exception getting data: " + e;
  }
  throw "Failed to get data";
};
const getReportsBucketByRegion = region => {
  return `${region}-${settings.Tenant}-${settings.contentReportsS3Bucket}`;
};
const getRegionDataItems = () => {
  const allRegions = [settings.CoreRegion, ...settings.SatelliteRegions];
  return allRegions.map(item => {
    return {
      id: item,
      reportsBucket: `${item}-${settings.Tenant}-${settings.contentReportsS3Bucket}`,
      name: settings.RegionNames ? settings.RegionNames[item] || item : item
    };
  });
};
const getLessonHistoryByTime = async ({
  marketIds,
  startTimeEpoch,
  endTimeEpoch
}) => {
  try {
    const hashedPW = getHashedPW();
    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      responsibleParty: "results",
      startTimeEpoch,
      endTimeEpoch,
      userType: distiAuthSettings.userType,
      marketIds
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_lesson_data_for_time_period"
    });

    //console.log("Got result of get_month_data_for_responsible_party: "+JSON.stringify(result));

    // Now keep trying to read the returned URL until time expires
    // We are in an async, so not in a hurry to return
    const tryUntil = new Date() / 1000 + 500; /* seconds */
    while (new Date() / 1000 < tryUntil) {
      //result.expiresAt)
      try {
        const s3GetResult = await axios({
          method: "GET",
          //headers: { 'content-type': 'application/json' },
          url: result.data.url,
          responseType: "stream"
        });

        console.log("The getLessonHistoryByYearMonth file must be ready now");

        // Return the url so it can be downloaded
        return result.data.url;
      } catch (e) {
        console.log("got exception: " + e);
      }
      await sleep(3000);
    }
  } catch (e) {
    console.log("Got exception from get_month_data_in_region: " + e);
    throw "Got exception getting data: " + e;
  }
  throw "Failed to get data";
};

const getSignedKinesisURL = async (params, onSuccess, onFailure) => {
  console.log(
    "getSignedKinesisURL request params: " + JSON.stringify(params, null, 2)
  );

  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      region: params.region,

      endpoint: params.endpoint,
      queryParams: JSON.stringify(params.queryParams)
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_signed_kinesis_url"
    });

    //console.log("Got result of get_signed_kinesis_url: "+JSON.stringify(result));

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from getSignedKinesisURL: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};

const getCurrentChatDataByStudent = async (studentName, classId) => {
  const hashedPW = getHashedPW();
  try {
    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      classId: classId,
      studentName
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_current_chat_data"
    });

    // console.log("Got result of report_user_status_user_thumbnail: "+JSON.stringify(result));

    return result.data || [];
  } catch (e) {
    console.log("Got exception trying to call get_current_chat_data: " + e);
    return null;
  }
};

const addToCurrentChatDataByStudent = async (
  studentName,
  classId,
  newMessage
) => {
  const hashedPW = getHashedPW();
  try {
    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      classId: classId,
      studentName,
      newMessage
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/add_to_current_chat_data"
    });

    // console.log("Got result of report_user_status_user_thumbnail: "+JSON.stringify(result));

    return result.data || [];
  } catch (e) {
    console.log("Got exception trying to call add_to_current_chat_data: " + e);
    return null;
  }
};

const getKinesisAccessData = async (params, onSuccess, onFailure) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      region: params.region,

      natTraversalDisabled: params.natTraversalDisabled,
      forceTURN: params.forceTURN,
      isMaster: params.isMaster,
      studentName: params.studentId
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_kinesis_access_data"
    });

    //console.log("Got result of get_kinesis_access_data: "+JSON.stringify(result, null, 2));

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from getKinesisAccessData: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};
const getContentUploadZipUrl = async (params, onSuccess, onFailure) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      //region: params.region,
      contentId: params.contentId
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_content_upload_zip_url"
    });

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from getContentUploadZipUrl: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};
const getContentUploadImageUrl = async (params, onSuccess, onFailure) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      //region: params.region,
      contentId: params.contentId
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_content_upload_image_url"
    });

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from getContentUploadImageUrl: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};
const uploadContentImageBlob = async ({ contentId, blob }) => {
  let goodUpload = false;
  // Upload the blob as an image.png file
  try {
    const postData = JSON.parse(
      JSON.stringify(await getContentUploadImageUrl({ contentId }))
    );
    const data = new FormData();
    Object.keys(postData.fields).forEach(key =>
      data.append(key, postData.fields[key])
    );

    data.append("file", blob);

    const result = await axios.post(postData.url, data);

    goodUpload = true;
  } catch (e) {
    console.log("Image upload problem:", e);
  }
  return goodUpload;
};

const getUserThumbnailUploadUrl = async (params, onSuccess, onFailure) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      region: params.region,
      classId: params.classId
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_webcam_upload_url"
    });

    //console.log("Got result of get_webcam_upload_url: "+JSON.stringify(result));

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from getUserThumbnailUploadUrl: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};

const reportUserStatusUserThumbnail = async (params, onSuccess, onFailure) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      region: params.region,
      classId: params.classId,
      filename: params.filename
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/report_user_status_user_thumbnail"
    });

    // console.log("Got result of report_user_status_user_thumbnail: "+JSON.stringify(result));

    if (onSuccess) onSuccess(result.data);
    return result.data;
  } catch (e) {
    console.log("Got exception from reportUserStatusUserThumbnail: " + e);
    if (onFailure) onFailure(e);
    return;
  }
};

const deleteVideoFromS3 = async (
  { region, videoKey },
  onSuccess,
  onFailure
) => {
  try {
    const hashedPW = getHashedPW();

    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      region,
      videoKey
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/delete_specific_video"
    });

    if (onSuccess) onSuccess();
  } catch (e) {
    console.log("Got exception from deleteVideoFromS3: ", e);
    if (onFailure) onFailure(e);
  }
};

let globalInstructorSelectedClassId = "";

const getUserType = () => {
  return distiAuthSettings.userType;
};
const getUsername = () => {
  return usernameStorage.username;
};
let selectedClass = "";

const getSchoolhousePricing = async (usersPerMonth, totalHoursPerMonth) => {
  const hashedPW = getHashedPW();
  try {
    let q = querystring.stringify({
      username: hashedPW.username,
      hash: hashedPW.hash,
      userType: distiAuthSettings.userType,
      usersPerMonth,
      totalHoursPerMonth
    });

    const result = await axios({
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: q,
      url: distiAuthSettings.baseURL + "api/get_schoolhouse_pricing"
    });

    console.log(
      "Got result of get_schoolhouse_pricing: " + JSON.stringify(result)
    );

    return JSON.parse(result.data || "[]");
  } catch (e) {
    console.log("Got exception trying to call get_schoolhouse_pricing: " + e);
    return null;
  }
};

const renameStudents = async (emails, anonymize) => {
  const hashedPW = getHashedPW();
  const arg = JSON.stringify({ lookupTable: JSON.stringify(emails), anonymize: JSON.stringify(anonymize)});
  console.log("renameStudents - arg: ", arg);

  let q = querystring.stringify({
    username: hashedPW.username,
    hash: hashedPW.hash,
    userType: distiAuthSettings.userType,
    region: runningFromRegion,
    arguments: arg,
  });

  console.log(q);
  const result = await axios({
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: q,
    url: distiAuthSettings.baseURL + "api/renameStudents"
  });

  return result.data;
}

const useRenameSystem = (anonymize) => {
  const [active, setActive] = React.useState(false);
  const [statusDetails, setStatusDetails] = React.useState(null);
  const performRename = React.useCallback(async (nameMapping) => {
    setActive(true)
    let resultData;

    resultData = await renameStudents(nameMapping, anonymize);

    console.log("resultData: ", resultData);

    const expireTime = 180; /*seconds*/
    const nowTime = new Date() / 1000;
    const expiresAt = nowTime + expireTime;

    while (true) {
      // get the status from S3 and call setStatusDetails()
      try {
        const s3GetResult = await axios({
          method: "GET",
          headers: { 'content-type': 'application/json' },
          url: resultData.url,
        });

        console.log("result: ", s3GetResult);
        setStatusDetails(s3GetResult.data);

        // break when done
        break;
      } catch (e) {
        console.log("Waiting for first status update");
      }

      await sleep(3000);
    }

    while (true) {
      try {
        const s3GetResult = (await axios({
          method: "GET",
          headers: { 'content-type': 'application/json' },
          url: resultData.url,
        })).data;

        console.log("result: ", s3GetResult, statusDetails);

        setStatusDetails(s3GetResult);

        if (s3GetResult.doneCount >= s3GetResult.startCount || s3GetResult.cancel === true) {
          console.log("Should break out of loop");
          break;
        }
      } catch (e) {
        console.log("got exception: " + e);
        // REAL PROBLEM DETECTED
      }

      await sleep(3000);
    }

    setActive(false)
  }, [setActive, setStatusDetails])
  return [active, statusDetails, performRename]
}



export default {
  selectedClass,
  runningFromRegion,
  init,
  hashPassword,
  storeHashedPW,
  getHashedPW,
  clearHashedPW,
  requestPasswordReset,
  getCognitoToken,
  getCredentialsUpdatedAsNeeded,
  applyNewPassword,
  getStreamingURL,
  getAvailableClasses,
  requestInviteEmail,
  getUserData,
  AllowedActionsContext,
  getAccessHistoryByYearMonthForResponsibleParty,
  recordAgreementAcceptance,
  getSignedKinesisURL,
  getKinesisAccessData,
  getContentUploadZipUrl,
  getContentUploadImageUrl,
  uploadContentImageBlob,
  getUserThumbnailUploadUrl,
  reportUserStatusUserThumbnail,
  getUserType,
  getUsername,
  getCurrentChatDataByStudent,
  addToCurrentChatDataByStudent,
  globalInstructorSelectedClassId,
  deleteVideoFromS3,
  getLessonHistoryByTime,
  getReportsBucketByRegion,
  getRegionDataItems,
  getSchoolhousePricing,
  allowedActionsForRoles,
  getDocumentationCredentials,
  useRenameSystem,
};
