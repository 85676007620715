import gql from "graphql-tag";

export default {
  STUDENTS: {
    ALL: gql(`
            query listStudents($nextToken: String, $limit: Int) {
               listStudents(nextToken: $nextToken, limit: $limit) {
                items {
                  id: pk
                  fullName
                  market
                  dealer
                  personId
                }
                nextToken
              }
            }`),
    ALL_SUBSCRIPTION: gql`
      subscription {
        onStudentsChange {
          id: pk
          fullName
          market
          dealer
          deleted
          personId
        }
      }
    `,
    CREATE: gql`
      mutation createStudent(
        $pk: String!
        $fullName: String!
        $market: String
        $dealer: String
        $personId: String
      ) {
        createStudent(
          input: {
            pk: $pk
            fullName: $fullName
            market: $market
            dealer: $dealer
            personId: $personId
          }
        ) {
          pk
          fullName
          market
          dealer
          personId
        }
      }
    `,
    UPDATE: gql`
      mutation updateStudent(
        $pk: String!
        $fullName: String
        $market: String
        $dealer: String
        $personId: String
      ) {
        updateStudent(
          input: {
            pk: $pk
            fullName: $fullName
            market: $market
            dealer: $dealer
            personId: $personId
          }
        ) {
          pk
          fullName
          market
          dealer
          personId
        }
      }
    `,
    DELETE: gql`
      mutation deleteStudent($pk: String!) {
        deleteStudent(input: { pk: $pk }) {
          pk
          deleted
        }
      }
    `
  },
  CLASSES: {
    ALL: gql(`
            query {
               listClasses {
                items {
                  id: pk
                  description
                  startDate
                  endDate
                  dailyStartTime
                  dailyEndTime
                  content
                  region
                  students
                  responsibleParty
                  market
                  useWebcam
                  captureVideo
                  suppressReportResults
                  hideChat
                  contentSettings
                  singleLesson
                  lessonMods
                }
              }
            }`),
    ALL_SUBSCRIPTION: gql`
      subscription {
        onClassChange {
          id: pk
          description
          startDate
          endDate
          dailyStartTime
          dailyEndTime
          students
          content
          region
          responsibleParty
          market
          useWebcam
          captureVideo
          suppressReportResults
          hideChat
          contentSettings
          singleLesson
          lessonMods
          deleted
        }
      }
    `,
    MODIFY: gql`
      mutation modifyClass(
        $pk: String!
        $description: String!
        $startDate: Int!
        $endDate: Int!
        $dailyStartTime: Int!
        $dailyEndTime: Int!
        $content: [Int]!
        $students: [String]!
        $region: String!
        $responsibleParty: String!
        $market: String
        $useWebcam: Boolean
        $captureVideo: Boolean
        $suppressReportResults: Boolean
        $hideChat: Boolean
        $contentSettings: String
        $singleLesson: Boolean
        $lessonMods: String
      ) {
        modifyClass(
          input: {
            pk: $pk
            description: $description
            startDate: $startDate
            endDate: $endDate
            dailyStartTime: $dailyStartTime
            dailyEndTime: $dailyEndTime
            content: $content
            students: $students
            region: $region
            responsibleParty: $responsibleParty
            market: $market
            useWebcam: $useWebcam
            captureVideo: $captureVideo
            suppressReportResults: $suppressReportResults
            hideChat: $hideChat
            contentSettings: $contentSettings
            singleLesson: $singleLesson
            lessonMods: $lessonMods
          }
        ) {
          pk
          description
          startDate
          endDate
          content
          dailyStartTime
          dailyEndTime
          students
          region
          responsibleParty
          market
          useWebcam
          captureVideo
          suppressReportResults
          hideChat
          contentSettings
          singleLesson
          lessonMods
        }
      }
    `,
    DELETE: gql`
      mutation deleteClass($pk: String!) {
        deleteClass(input: { pk: $pk }) {
          pk
          deleted
        }
      }
    `,
    CREATE: gql`
      mutation {
        createClass {
          pk
        }
      }
    `
  },
  USERS: {
    STATUS: gql(
      `query {
              listUserStatus {
                items {
                    userId
                    updateTime
                    currentClass
                    elapsedTime
                    percentComplete
                    trainingMode
                    currentLesson
                    currentContent
                    currentRegion
                    loginTime
                    countryCode
                    deleteStatusAt
                    launchURL
                    contentThumbnail        
                    userThumbnail
                }
              }
            }
            `
    ),
    STATUS_SUBSCRIPTION: gql`
      subscription {
        onUserStatusChange {
          userId
          updateTime
          currentClass
          elapsedTime
          percentComplete
          trainingMode
          currentLesson
          currentContent
          currentRegion
          loginTime
          countryCode
          deleteStatusAt
          launchURL
          contentThumbnail
          userThumbnail
        }
      }
    `
  },
  LESSON_MOD: {
    ALL: gql(`
      query {
        listLessonMod {
          items {
            id: pk
            availableMarkets
            content
            dateCreated
            marketCreated
            valid
            name
            zipGuid
          }
        }
      }
    `),
    ALL_SUBSCRIPTION: gql(`
      subscription {
        onLessonModChange {
          id: pk
          availableMarkets
          content
          dateCreated
          marketCreated
          valid
          name
          zipGuid
        }
      }
    `),
    MODIFY: gql(`
      mutation modifyLessonMod (
        $pk: String!
        $availableMarkets: [String]
        $content: String
        $dateCreated: String
        $marketCreated: String
        $valid: Boolean
        $name: String
        $zipGuid: String
      ) {
        modifyLessonMod(
          input: {
            pk: $pk
            availableMarkets: $availableMarkets
            content: $content
            dateCreated: $dateCreated
            marketCreated: $marketCreated
            valid: $valid
            name: $name
            zipGuid: $zipGuid
          }
        ) {
          pk
          availableMarkets
          content
          dateCreated
          marketCreated
          valid
          name
          zipGuid
        }
      }
    `),
  },
  CONTENT: {
    ALL: gql(`
            query {
               listContent {
                items {
                  id: pk
                  name
                  description
                  highlights
                  availableSettings
                  lessonPassCriteria
                  startFileName
                  stopFileName
                  supportsLessonModification
                  executableNameForRunningCheck
                  tags
                  zipLastUpdated        
                  whoLastUpdatedZip     
                }
              }
            }`),

    MODIFY_PASS: gql`
      mutation updateContentPassCriteria(
        $pk: String!
        $lessonPassCriteria: String!
      ) {
        updateContentPassCriteria(
          input: { pk: $pk, lessonPassCriteria: $lessonPassCriteria }
        ) {
          id: pk
          name
          availableSettings
          lessonPassCriteria
        }
      }
    `,
    MODIFY: gql`
      mutation updateContent(
        $pk: String!
        $name: String
        $description: String
        $highlights: String
        $lessonPassCriteria: String
        $availableSettings: String
        $startFileName: String
        $stopFileName: String
        $supportsLessonModification: Boolean
        $executableNameForRunningCheck: String
        $tags: [String]
        $zipLastUpdated: String
        $whoLastUpdatedZip: String
      ) {
        updateContent(
          input: {
            pk: $pk
            lessonPassCriteria: $lessonPassCriteria
            name: $name
            description: $description
            highlights: $highlights
            lessonPassCriteria: $lessonPassCriteria
            availableSettings: $availableSettings
            startFileName: $startFileName
            stopFileName: $stopFileName
            supportsLessonModification: $supportsLessonModification
            executableNameForRunningCheck: $executableNameForRunningCheck
            tags: $tags
            zipLastUpdated: $zipLastUpdated
            whoLastUpdatedZip: $whoLastUpdatedZip
          }
        ) {
          id: pk
          name
          description
          highlights
          lessonPassCriteria
          availableSettings
          startFileName
          stopFileName
          supportsLessonModification
          executableNameForRunningCheck
          tags
          zipLastUpdated
          whoLastUpdatedZip
        }
      }
    `
  },
  // REGIONS:{
  // 	ALL: gql(`
  //           query {
  //              listRegions {
  //               items {
  //                 id: pk
  //                 name
  //               }
  //             }
  //           }`)
  // },
  TRAINERS: {
    ALL: gql(`
            query {
               listTrainers {
                items {
                  id: pk
                  fullName
                  roles
                  markets
                }
              }
            }`),
    ALL_SUBSCRIPTION: gql(`
            subscription {
                onTrainerChange {
                  id: pk
                  fullName
                  roles
                  markets
                  deleted
                }
            }
            `),
    MODIFY: gql`
      mutation modifyTrainer(
        $pk: String!
        $fullName: String
        $roles: [String]
        $markets: [String]
      ) {
        modifyTrainer(
          input: {
            pk: $pk
            fullName: $fullName
            roles: $roles
            markets: $markets
          }
        ) {
          pk
          fullName
          roles
          markets
        }
      }
    `,
    DELETE: gql`
      mutation deleteTrainer($pk: String!) {
        deleteTrainer(input: { pk: $pk }) {
          pk
          deleted
        }
      }
    `
  },
  MARKETS: {
    ALL: gql(`
            query {
               listMarkets {
                items {
                  id: pk
                  name
                  content
                  defaultRegion
                }
              }
            }`),
    ALL_SUBSCRIPTION: gql(`
            subscription {
                onMarketChange {
                  id: pk
                  name
                  content
                  defaultRegion
                  deleted
                }
            }
            `),
    MODIFY: gql`
      mutation modifyMarket(
        $pk: String!
        $name: String
        $defaultRegion: String
        $content: [Int]
      ) {
        modifyMarket(
          input: {
            pk: $pk
            name: $name
            defaultRegion: $defaultRegion
            content: $content
          }
        ) {
          pk
          name
          content
          defaultRegion
        }
      }
    `,
    DELETE: gql`
      mutation deleteMarket($pk: String!) {
        deleteMarket(input: { pk: $pk }) {
          pk
          deleted
        }
      }
    `,
    CREATE: gql`
      mutation {
        createMarket {
          pk
        }
      }
    `
  }
};
